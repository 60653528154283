import {
    GET_CAMPAIGN_INACTIVE_ITEM_REQUEST,
    GET_CAMPAIGN_INACTIVE_ITEM_SUCCESS,
    ON_SHOW_LOADER_GET_CAMPAIGN_INACTIVE_ITEM,
    ON_HIDE_LOADER_GET_CAMPAIGN_INACTIVE_ITEM,
    GET_CAMPAIGN_INACTIVE_DATA_REQUEST,
    GET_CAMPAIGN_INACTIVE_DATA_SUCCESS,
    ON_SHOW_LOADER_GET_CAMPAIGN_INACTIVE_DATA,
    ON_HIDE_LOADER_GET_CAMPAIGN_INACTIVE_DATA,
    RESET_CAMPAIGN_INACTIVE_DATA,
    EXPORT_CAMPAIGN_INACTIVE_TO_CSV_REQUEST,
    EXPORT_CAMPAIGN_INACTIVE_TO_CSV_PROGRESS,
    EXPORT_CAMPAIGN_INACTIVE_TO_CSV_SUCCESS,
    EXPORT_CAMPAIGN_INACTIVE_TO_CSV_FAILURE
} from 'constants/ActionTypes';


// export const getCampaignInactiveItemRequest = (params) => ({
//   type: GET_CAMPAIGN_INACTIVE_ITEM_REQUEST,
//   payload: params
// });
export const getCampaignInactiveItemRequest = () => {
  return {
    type: GET_CAMPAIGN_INACTIVE_ITEM_REQUEST,
  };  
};
export const getCampaignInactiveItemSuccess = items => {
  return {
    type: GET_CAMPAIGN_INACTIVE_ITEM_SUCCESS,
    payload: items
  };
};
export const showLoaderCampaignInactiveItem = () => {
  return {
    type: ON_SHOW_LOADER_GET_CAMPAIGN_INACTIVE_ITEM
  };
};
export const hideLoaderCampaignInactiveItem = () => {
  return {
    type: ON_HIDE_LOADER_GET_CAMPAIGN_INACTIVE_ITEM
  };
};

export const getCampaignInactiveDataRequest = (campaignId) => {
  return {
    type: GET_CAMPAIGN_INACTIVE_DATA_REQUEST,
    payload: { 
      campaignId: campaignId
    }
  };
};
export const getCampaignInactiveDataSuccess = items => {
  return {
    type: GET_CAMPAIGN_INACTIVE_DATA_SUCCESS,
    payload: items
  };
};
export const showLoaderCampaignInactiveData = () => {
  return {
    type: ON_SHOW_LOADER_GET_CAMPAIGN_INACTIVE_DATA
  };
};
export const hideLoaderCampaignInactiveData = () => {
  return {
    type: ON_HIDE_LOADER_GET_CAMPAIGN_INACTIVE_DATA
  };
};
export const resetCampaignInactiveData = () => {
  return {
    type: RESET_CAMPAIGN_INACTIVE_DATA
  };
};
export const exportToCSV = (data, fileName, headers) => {
  return {
    type: EXPORT_CAMPAIGN_INACTIVE_TO_CSV_REQUEST,
    payload: { 
      data: data, 
      fileName: fileName, 
      headers: headers 
    }
  };
};

export const exportToCSVProgress = (progress) => {
  return {
    type: EXPORT_CAMPAIGN_INACTIVE_TO_CSV_PROGRESS,
    payload: progress
  };
};

export const exportToCSVSuccess = () => {
  return {
    type: EXPORT_CAMPAIGN_INACTIVE_TO_CSV_SUCCESS
  };
};

export const exportToCSVFailure = (error) => {
  return {
    type: EXPORT_CAMPAIGN_INACTIVE_TO_CSV_FAILURE,
    payload: error
  };
};