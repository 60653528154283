import { all } from 'redux-saga/effects';
import authSagas from './Auth';
import registerUserCountSagas from './RegisterUserCount';
import dealersSagas from './Dealers';
import serviceSurveySagas from './ServiceSurvey';
import usersSagas from './Users';
import chatsSagas from './Chats';
import rewardSagas from './Reward';
import redeemSagas from './Redeem';
import changePhoneSagas from './ChangePhone';
import secondHandCarSagas from './SecondHandCar';
import analyticsSagas from './Analytics';
import contactusSagas from './ContactUs';
import rankingSagas from './Ranking';
import searchUserSagas from './SearchUser';
import activeUsersSagas from './ActiveUsers';
import deleteUserDataSagas from './DeleteUserData';
import campaignRankSagas from './CampaignRank';
import campaignInactiveSagas from './CampaignInactive';
import campaignRankReportSagas from './CampaignRankReport';
import campaignInactiveReportSagas from './CampaignInactiveReport';
import resetPageSagas from './ResetPage';


export default function* rootSaga(getState) {
  //yield all([authSagas()]);
  yield all([
    authSagas(),
    registerUserCountSagas(),
    dealersSagas(),
    serviceSurveySagas(),
    usersSagas(),
    chatsSagas(),
    rewardSagas(),
    redeemSagas(),
    changePhoneSagas(),
    secondHandCarSagas(),
    analyticsSagas(),
    contactusSagas(),
    rankingSagas(),
    searchUserSagas(),
    activeUsersSagas(),
    deleteUserDataSagas(),
    campaignRankSagas(),
    campaignInactiveSagas(),
    campaignRankReportSagas(),
    campaignInactiveReportSagas(),
    resetPageSagas(),
  ]);
}
