import {
    GET_CAMPAIGN_RANK_ITEM_REQUEST,
    GET_CAMPAIGN_RANK_ITEM_SUCCESS,
    ON_SHOW_LOADER_GET_CAMPAIGN_RANK_ITEM,
    ON_HIDE_LOADER_GET_CAMPAIGN_RANK_ITEM,
    GET_CAMPAIGN_RANK_DATA_REQUEST,
    GET_CAMPAIGN_RANK_DATA_SUCCESS,
    ON_SHOW_LOADER_GET_CAMPAIGN_RANK_DATA,
    ON_HIDE_LOADER_GET_CAMPAIGN_RANK_DATA,
    RESET_CAMPAIGN_RANK_DATA,
    EXPORT_CAMPAIGN_RANK_TO_CSV_REQUEST,
    EXPORT_CAMPAIGN_RANK_TO_CSV_PROGRESS,
    EXPORT_CAMPAIGN_RANK_TO_CSV_SUCCESS,
    EXPORT_CAMPAIGN_RANK_TO_CSV_FAILURE
} from 'constants/ActionTypes';


export const getCampaignRankItemRequest = () => {
  return {
    type: GET_CAMPAIGN_RANK_ITEM_REQUEST,
  };  
};
export const getCampaignRankItemSuccess = items => {
  return {
    type: GET_CAMPAIGN_RANK_ITEM_SUCCESS,
    payload: items
  };
};
export const showLoaderCampaignRankItem = () => {
  return {
    type: ON_SHOW_LOADER_GET_CAMPAIGN_RANK_ITEM
  };
};
export const hideLoaderCampaignRankItem = () => {
  return {
    type: ON_HIDE_LOADER_GET_CAMPAIGN_RANK_ITEM
  };
};

export const getCampaignRankDataRequest = (campaignId) => {
  return {
    type: GET_CAMPAIGN_RANK_DATA_REQUEST,
    payload: { 
      campaignId: campaignId
    }
  };
};   
export const getCampaignRankDataSuccess = items => {
  return {
    type: GET_CAMPAIGN_RANK_DATA_SUCCESS,
    payload: items
  };
};
export const showLoaderCampaignRankData = () => {
  return {
    type: ON_SHOW_LOADER_GET_CAMPAIGN_RANK_DATA
  };
};
export const hideLoaderCampaignRankData = () => {
  return {
    type: ON_HIDE_LOADER_GET_CAMPAIGN_RANK_DATA
  };
};
export const resetCampaignRankData = () => {
  return {
    type: RESET_CAMPAIGN_RANK_DATA
  };
};
export const exportToCSV = (data, fileName, headers) => {
  return {
    type: EXPORT_CAMPAIGN_RANK_TO_CSV_REQUEST,
    payload: { 
      data: data, 
      fileName: fileName, 
      headers: headers 
    }
  }
};

export const exportToCSVProgress = (progress) => {
  return {
    type: EXPORT_CAMPAIGN_RANK_TO_CSV_PROGRESS,
    payload: progress
  }
};

export const exportToCSVSuccess = () => {
  return {
    type: EXPORT_CAMPAIGN_RANK_TO_CSV_SUCCESS
  }
};

export const exportToCSVFailure = (error) => {
  return {
    type: EXPORT_CAMPAIGN_RANK_TO_CSV_FAILURE,
    payload: error
  }
};

 
