import React from "react";
//import { usePageState } from 'hooks/usePageState';
import ContainerHeader from "components/ContainerHeader/index";
import IntlMessages from "util/IntlMessages";
import withTranslatedSwal from "util/withTranslatedSwal";
import { connect } from "react-redux";
import {
  getApiUserRequest,
  getUserByOldPhoneRequest,
  getUserByNewPhoneRequest,
  changePhoneRequest,
  resetChangePhone,
  showApiUserLoader,
  showOldPhoneLoader,
  showNewPhoneLoader,
  showChangePhoneLoader,
  showMessageChangePhone,
  hideMessageChangePhone,
} from "actions/ChangePhone";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import CardBox from "components/CardBox";
import ListDividers from "app/routes/components/routes/dividers/list/ListDividers";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Link } from "react-router-dom";
import {
  ContactsOutlined,
  FilterCenterFocusOutlined,
} from "@material-ui/icons";
import { Redirect } from "react-router-dom";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import ResetButton from "components/ResetButton";
import { PAGE_IDS } from "constants/PageConstants";
import { resetPage, updateFormData } from "actions";
import Swal from 'sweetalert2';
import { FormattedMessage } from 'react-intl';

class ChangePhone extends React.Component {
  constructor(props) {
    super(props);

    const initialState = {
      oldPhone: "",
      newPhone: "",
      vinNumber: "",
      userId: "",
    }

    //const [formState, updateFormState, resetForm] = usePageState('change-phone-page', initialState);


    this.state = {
      oldPhone: "",
      newPhone: "",
      vinNumber: "",
      userId: "",
    };
    //let passwordinput;
    this.handleSubmit = this.handleSubmit.bind(this);
    this.cancelPress = this.cancelPress.bind(this);
    this.handleReset = this.handleReset.bind(this);
    // preserve the initial state in a new object
    this.baseState = this.state;
  }

  // const formData = useSelector(state => state.changePhone.formData);
  //   console.log("olePhone:" + formData.oldPhone);


  handleReset = () => {
    
    document.getElementById("change-phone").reset();
    this.setState({
      oldPhone: "",
      newPhone: "",
      vinNumber: "",
      userId: "",
    });

    this.props.updateFormData(
      'changePhone',
      'oldPhone', 
      ''
    );

    this.props.updateFormData(
      'changePhone',
      'newPhone', 
      ''
    );

    this.props.updateFormData(
      'changePhone',
      'vinNumber', 
      ''
    );

    this.setState(this.baseState);
    this.props.resetChangePhone();

    localStorage.removeItem('changePhoneUserId');
    this.props.resetPage(PAGE_IDS.CHANGE_PHONE);
  }

  handleSubmit(e) {
    e.preventDefault();
    e.target.reset();

    // // Optional: Add any submission logic here
    // try {
    //   // Dispatch reset action
    //   this.props.resetPage(PAGE_IDS.CHANGE_PHONE);
      
    //   // Show success notification
    //   NotificationManager.success(
    //     'Form submitted and reset successfully', 
    //     'Submission Successful', 
    //     3000
    //   );
    // } catch (error) {
    //   // Show error notification if submission fails
    //   NotificationManager.error(
    //     'Failed to submit form', 
    //     'Submission Error', 
    //     3000
    //   );
    // }
  }



  changePhonePress = () => {
    //console.log('userId:' + this.state.userId);

    // const { formData, langFormData } = this.props;
    // const changePhoneData = langFormData.changePhone || formData.changePhone;

    // if (changePhoneData.oldPhone === "") {
    //   NotificationManager.warning(
    //     <IntlMessages id="notification.warning" />,
    //     <IntlMessages id="pages.changephone.message.oldphoneempty" />,
    //     3000
    //   );
    //   return;
    // }
    // // if (this.state.newPhone === "") {
    // if (changePhoneData.newPhone === "") {
    //   // NotificationManager.warning(
    //   //   <IntlMessages id="pages.createreward.message.orderempty" />
    //   // );
    //   NotificationManager.warning(
    //     <IntlMessages id="notification.warning" />,
    //     <IntlMessages id="pages.changephone.message.newphoneempty" />,
    //     3000
    //   );
    //   return;
    // }
    // // if (this.state.vinNumber === "") {
    // if (changePhoneData.vinNumber === "") {
    //   NotificationManager.warning(
    //     <IntlMessages id="notification.warning" />,
    //     <IntlMessages id="pages.changephone.message.vinnumberempty" />,
    //     3000
    //   );
    //   return;
    // }

    // console.log('userId:' + this.state.userId);
    // console.log('oldPhone:' + this.state.oldPhone);
    // console.log('newPhone:' + this.state.newPhone);
    // console.log('vinNumber:' + this.state.vinNumber);

    // let title = 'Confirm Phone Change';
    // let text = 'Are you sure you want to change your phone number?';
    // let confirm = 'Yes, change it!';
    // let cancel = 'No, cancel';

    // let title = '';
    // let text = '';
    // let confirm = '';
    // let cancel = '';

    // if (this.props.locale === 'en') {
    //   title = 'Confirm Phone Change';
    //   text = 'Are you sure you want to change your phone number?';
    //   confirm = 'Yes, change it!';
    //   cancel = 'No, cancel';
    // } else if (this.props.locale === 'th') {
    //   title = 'ยืนยันการเปลี่ยนเบอร์โทร';
    //   text = 'คุณต้องการที่จะเปลี่ยนเบอร์โทรนี้?';
    //   confirm = 'ใช่, เปลี่ยนเลย!';
    //   cancel = 'ไม่ใช่, ยกเลิก';
    // }

    // Show custom confirmation dialog
    // Swal.fire({
    //   title: <IntlMessages id="pages.changephone.message.alert.title" />,
    //   text:  text,
    //   icon: 'warning',
    //   showCancelButton: true,
    //   confirmButtonColor: '#3085d6',
    //   cancelButtonColor: '#d33',
    //   confirmButtonText: confirm,
    //   cancelButtonText: cancel,
    //   // title: `${<IntlMessages id="pages.changephone.message.alert.title" />}`,
    //   // text:  <FormattedMessage id="pages.changephone.message.alert.text" />,
    //   // icon: 'warning',
    //   // showCancelButton: true,
    //   // confirmButtonColor: '#3085d6',
    //   // cancelButtonColor: '#d33',
    //   // confirmButtonText: <FormattedMessage id="pages.changephone.message.alert.confirm" />,
    //   // cancelButtonText: <FormattedMessage id="pages.changephone.message.alert.cancel" />,
    // })
    this.props.translateSwal({
      title: <IntlMessages id="pages.changephone.message.alert.title" />,
      text:  <IntlMessages id="pages.changephone.message.alert.text" />,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: <IntlMessages id="pages.changephone.message.alert.confirm" />,
      cancelButtonText: <IntlMessages id="pages.changephone.message.alert.cancel" />,
      preConfirm: () => {
        return true; // Return value will be available in result.value
      },
      allowOutsideClick: false // Prevent dismissing by clicking outside
      // customClass: {
      //   container: 'swal2-container',
      //   popup: 'swal2-popup'
      // },
      // didOpen: () => {
      //   document.body.classList.add('swal2-shown');
      // },
      // willClose: () => {
      //   document.body.classList.remove('swal2-shown');
      // }

    }).then((result) => {
      console.log('result;;; ' + result);
      console.log('isConfiremed ' + result.isConfirmed);
      if (result.isConfirmed) {
        try {
          // Your existing submission logic

          const { formData, langFormData } = this.props;
          const changePhoneData = langFormData.changePhone || formData.changePhone;

          this.props.showChangePhoneLoader();
          this.props.changePhoneRequest(localStorage.getItem('changePhoneUserId'), changePhoneData.oldPhone, changePhoneData.newPhone, changePhoneData.vinNumber);


          const isMessageChangePhone = this.props.isMessageChangePhone;
          const changePhoneCode = this.props.changePhoneCode;

          //console.log('isMessageChangePhone:' + isMessageChangePhone);
          //console.log('changePhoneCode:' + changePhoneCode);

          // if (isMessageChangePhone) {
          //   //this.displayMessage(changePhoneCode);            


          //   if (changePhoneCode === 100) {
              
          //     this.props.translateSwal({
          //       title: <IntlMessages id="pages.changephone.message.success.title" />,
          //       text:  <IntlMessages id="pages.changephone.message.success.text" />,
          //       icon: 'success',
          //     })

          //     setTimeout(() => {
          //       NotificationManager.success(
          //         <IntlMessages id="pages.changephone.message.notification.success" />,
          //         <IntlMessages id="pages.changephone.message.notification.done" />,
          //         3000
          //       );
          //     }, 100);

          //     this.props.hideMessageChangePhone();
              
          //     this.cancelPress();

          //   } else {

          //     //console.log("B");
          //     this.props.translateSwal({
          //       title: <IntlMessages id="pages.changephone.message.error.title" />,
          //       text:  <IntlMessages id="pages.changephone.message.error.text" />,
          //       icon: 'error',
          //     })

          //     setTimeout(() => {
          //       NotificationManager.error(
          //         <IntlMessages id="pages.changephone.message.notification.failure" />,
          //         <IntlMessages id="pages.changephone.message.notification.fail" />,
          //         3000
          //       );
          //     }, 100);
          //   }
          // } 
                    
          // Show success notification
          // Swal.fire(
          //   'Changed!',
          //   'Your phone number has been changed.',
          //   'success'
          // );
        } catch (error) {
          // Show error notification
          // Swal.fire(
          //   'Error!',
          //   'Failed to change phone number.',
          //   'error'
          // );
        }
      } else {
        // Show cancellation notification
        NotificationManager.info(
          <IntlMessages id="pages.changephone.message.notification.cancel" />, 
          <IntlMessages id="pages.changephone.message.notification.submit.cancel" />, 
          3000
        );
      }
    }, (dismiss) => {
      console.log('dismiss reason:' + dismiss);
    }).catch(() =>  {
      console.log('Alert was dismissed')
    });
  }

  cancelPress() {
    //e.preventDefault();
    
    // Start Before

    document.getElementById("change-phone").reset();
    this.setState({
      oldPhone: "",
      newPhone: "",
      vinNumber: "",
      userId: "",
    });

    this.props.updateFormData(
      'changePhone',
      'oldPhone', 
      ''
    );

    this.props.updateFormData(
      'changePhone',
      'newPhone', 
      ''
    );

    this.props.updateFormData(
      'changePhone',
      'vinNumber', 
      ''
    );

    this.setState(this.baseState);
    this.props.resetChangePhone();    

    // NEW
    localStorage.removeItem('changePhoneUserId');
    this.props.resetPage(PAGE_IDS.CHANGE_PHONE);
    // NEW
    
    // End Before

    //resetForm();

    //this.displayAlert();


    // const childProps = { ...this.props };
    // delete childProps.apiUserItems;
    // delete childProps.oldPhoneUser;
    // delete childProps.isPhoneUsing;
    //window.location.reload(false);
  }

  

  // displayAlert() {
  //   <Dialog
  //       // open={open}
  //       // onClose={handleClose}
  //       aria-labelledby="alert-dialog-title"
  //       aria-describedby="alert-dialog-description"
  //     >
  //       <DialogTitle id="alert-dialog-title">
  //         {"Use Google's location service?"}
  //       </DialogTitle>
  //       <DialogContent>
  //         <DialogContentText id="alert-dialog-description">
  //           Let Google help apps determine location. This means sending anonymous
  //           location data to Google, even when no apps are running.
  //         </DialogContentText>
  //       </DialogContent>
  //       <DialogActions>
  //         <Button onClick={this.displaySuccessMessage()}>Disagree</Button>
  //         <Button onClick={this.displaySuccessMessage()} autoFocus>
  //           Agree
  //         </Button>
  //       </DialogActions>
  //     </Dialog>
  // }

  //NO USE using in componentDidUpdate instead
  displayMessage(isMessageChangePhone, changePhoneCode) {
    //console.log('displayMessage isMessageChangePhone:' + isMessageChangePhone + ' changePhoneCode:' + changePhoneCode);
    if (isMessageChangePhone) {
      if (changePhoneCode === 100) {
        // NotificationManager.success(
        //   <IntlMessages id="pages.changephone.message.notification.success" />,
        //   <IntlMessages id="pages.changephone.message.notification.done" />,
        //   3000
        // );

        this.props.translateSwal({
          title: <IntlMessages id="pages.changephone.message.success.title" />,
          text:  <IntlMessages id="pages.changephone.message.success.text" />,
          icon: 'success',
        })

        // setTimeout(() => {
        //   NotificationManager.success(
        //     <IntlMessages id="pages.changephone.message.notification.success" />,
        //     <IntlMessages id="pages.changephone.message.notification.done" />,
        //     3000
        //   );
        // }, 100);

        NotificationManager.success(
          <IntlMessages id="pages.changephone.message.notification.success" />,
          <IntlMessages id="pages.changephone.message.notification.done" />,
          3000
        );

        this.props.hideMessageChangePhone();
        
        this.cancelPress();
      } else {

        this.props.translateSwal({
          title: <IntlMessages id="pages.changephone.message.error.title" />,
          text:  <IntlMessages id="pages.changephone.message.error.text" />,
          icon: 'error',
        })

        NotificationManager.error(
          <IntlMessages id="pages.changephone.message.notification.failure" />,
          <IntlMessages id="pages.changephone.message.notification.fail" />,
          3000
        );
      }
    }
    // if (this.props.isMessageChangePhone) {
    //   this.props.hideMessageChangePhone();
    //   // setTimeout(() => {
    //   //   this.props.hideMessageReward();
    //   // }, 3000);
    // }
  }

  displaySuccessMessage() {
    // NotificationManager.success(
    //   this.props.locale === 'en' ? 'Success' : 'สำเร็จ',
    //   this.props.locale === 'en'
    //     ? 'Phone number has been changed.'
    //     : 'เปลี่ยนเบอร์โทรเรียบร้อยแล้ว'
    //     , 3000);
    //console.log('DD:' + changePhoneCode);
    NotificationManager.success(
      <IntlMessages id="pages.changephone.message.notification.success" />,
      <IntlMessages id="pages.changephone.message.notification.done" />,
      3000
    );
    // if (this.props.isMessageReward) {
    //   this.props.hideMessageReward();
    //   setTimeout(() => {
    //     this.props.hideMessageReward();
    //   }, 3000);
    // }
  }

  displayErrorMessage() {
    NotificationManager.error(
      <IntlMessages id="pages.changephone.message.notification.failure" />,
      <IntlMessages id="pages.changephone.message.notification.fail" />,
      3000
    );
    // if (this.props.isMessageReward) {
    //   this.props.hideMessageReward();
    //   setTimeout(() => {
    //     this.props.hideMessageReward();
    //   }, 3000);
    // }
  }

  componentDidMount() {
    // Set userId from props, local storage, or other source
    // console.log("uId1:" + this.props.location.state?.userId);
    // console.log("uId2:" + localStorage.getItem('userId'));
    // const userId = this.props.location.state?.userId 
    //   || localStorage.getItem('changePhoneUserId');

    // Set a flag to indicate this is not a language change
    localStorage.setItem('isPageNavigation', 'true');

    // Try to get userId from various sources

    //console.log('componentDidMount');

    // const userId = this.getUserId();  

    // console.log("uId1:" + userId);
    
    // if (userId) {
    //   this.setState({ userId });
    // }

    // if ((this.state.oldPhone !== '') && (this.state.oldPhone.length === 10)) {
    //   const userId = this.getUserId();  

    //   console.log("uId1:" + userId);
      
    //   if (userId) {
    //     this.setState({ userId });
    //   }
    // } else {
    //   localStorage.removeItem('changePhoneUserId');
    // }
    
  }

  
  // V1
  componentDidUpdate(prevProps) {
    //console.log('componentDidUpdate');

    const { formData, langFormData } = this.props;
    const changePhoneData = langFormData.changePhone || formData.changePhone;
    
    // if (this.props.isMessageChangePhone) {
    //   this.displayMessage(this.props.changePhoneCode);
    // }
    // Compare the current oldPhoneUser with the previous one
    if (prevProps.oldPhoneUser !== this.props.oldPhoneUser) {
      // Check if oldPhoneUser is defined and not null
      if (
        this.props.oldPhoneUser !== undefined &&
        this.props.oldPhoneUser !== null
      ) {
        // Use forEach instead of map for side effects
        this.props.oldPhoneUser.forEach((u) => {
          //console.log('chassisNumber1:' + u.chassisNumber)
          u.chassisNumber.forEach((c) => {
            //console.log('chassisNumber2:' + c)
            if (c === changePhoneData.vinNumber) {
              //console.log('userId1:' + u.objectId);
        
              //this.setState({ userId: u.objectId });
              //console.log('userId:2' + this.state.userId);
              
              // this.setState({ userId: u.objectId }, () => {
              //   localStorage.setItem('changePhoneUserId', u.objectId);
              //   //console.log('State after update:', this.state.userId);
              // });

              localStorage.setItem('changePhoneUserId', u.objectId);
            }
          });
        });
      }
    }

    // console.log('changePhoneCode1:' + prevProps.changePhoneCode);
    // console.log('changePhoneCode2:' + this.props.changePhoneCode);
    // console.log('changePhoneCode3:' + changePhoneData.changePhoneCode);
    // console.log('=========================')
    // console.log('isMessageChangePhone1:' + prevProps.isMessageChangePhone);
    // console.log('isMessageChangePhone1:' + this.props.isMessageChangePhone);


    if (prevProps.changePhoneCode !== this.props.changePhoneCode) {
      if (this.props.changePhoneCode) {
        if (this.props.changePhoneCode === 100) {
          // NotificationManager.success(
          //   <IntlMessages id="pages.changephone.message.notification.success" />,
          //   <IntlMessages id="pages.changephone.message.notification.done" />,
          //   3000
          // );
  
          this.props.translateSwal({
            title: <IntlMessages id="pages.changephone.message.success.title" />,
            text:  <IntlMessages id="pages.changephone.message.success.text" />,
            icon: 'success',
          })
  
          setTimeout(() => {
            NotificationManager.success(
              <IntlMessages id="pages.changephone.message.notification.success" />,
              <IntlMessages id="pages.changephone.message.notification.done" />,
              3000
            );
          }, 100);
  
          this.props.hideMessageChangePhone();
          
          this.cancelPress();
        } else {
          NotificationManager.error(
            <IntlMessages id="pages.changephone.message.notification.failure" />,
            <IntlMessages id="pages.changephone.message.notification.fail" />,
            3000
          );
        }
      }
    }

    //console.log('translateSwal isConfirmed1:' + prevProps.translateSwal.result.isConfirmed);
    //console.log('translateSwal isConfirmed2:' + this.props.translateSwal.result.isConfirmed);
    

    // if (this.props.location !== prevProps.location) {
    //   console.log("CLEAR USERID");
    //   localStorage.removeItem('changePhoneUserId');
    // }
  }

  componentWillUnmount() {
    // Clear localStorage when component is unmounted 
    // Not working when change language
    //console.log('componentWillUnmount');
    //console.log('isPageNavigation:' + localStorage.getItem('isPageNavigation'));

    const isPageNavigation = localStorage.getItem('isPageNavigation') === 'true';
    
    if (isPageNavigation) {
      // Clear userId only if it's a genuine page navigation
      this.cancelPress();
      localStorage.removeItem('changePhoneUserId');
    }
    
    // // Always reset the flag
    // localStorage.removeItem('isPageNavigation');

    //localStorage.removeItem('changePhoneUserId');

    //this.cancelPress();

    // const { formData, langFormData } = this.props;
    // const changePhoneData = langFormData.changePhone || formData.changePhone;

    // if ((changePhoneData.oldPhone !== '') && (changePhoneData.oldPhone.length === 10)) {
    //   console.log("AAA");
    //   const userId = this.getUserId();  

    //   console.log("uId1:" + userId);
      
    //   if (userId) {
    //     this.setState({ userId });
    //   }
    // } else {
    //   console.log("BBB");
    //   localStorage.removeItem('changePhoneUserId');
    // }
  }

  getUserId = () => {
    // Check multiple sources
    // 1. Check if passed via router state
    const routerStateUserId = this.props.location?.state?.userId;
    if (routerStateUserId) {
      return routerStateUserId;
    }

    // 2. Check localStorage
    const storedUserId = localStorage.getItem('changePhoneUserId');
    if (storedUserId) {
      return storedUserId;
    }

    // 3. Check if passed via props
    const propsUserId = this.props.userId;
    if (propsUserId) {
      return propsUserId;
    }

    // 4. Any other source you might have
    return null;
  }



  myCarDisplay = (apiItems) => {
    if (apiItems.STATUS_CODE === "100") {
      return (
        <ListItem>
          <ListItemIcon>
            <i className="zmdi zmdi-circle text-green" />
          </ListItemIcon>
          <ListItemText>
            {apiItems.CUSTOMER_NAME +
              " " +
              apiItems.CUSTOMER_LAST_NAME +
              " " +
              apiItems.DEALER_ID}
          </ListItemText>
        </ListItem>
      );
    } else {
      return (
        <ListItem>
          <ListItemIcon>
            <i className="zmdi zmdi-close-circle text-red" />
          </ListItemIcon>
          <ListItemText>
            <IntlMessages id="pages.changephone.label.dealernotchangedata" />
          </ListItemText>
        </ListItem>
      );
    }
  };

  oldPhoneDisplay = (oldItems, oldname) => {
    if (oldItems !== undefined && oldItems !== null) {
      if (oldItems.length > 0) {
        // console.log('userId1:' + this.state.userId);

        // oldItems.map(u => {
        //   console.log('userId2:' + u.objectId);
        //   //onsold.log('uChassis:' + u.chassisNumber)
        //   u.chassisNumber.map(c => {
        //     if (c === this.state.vinNumber) {
        //       this.setState({ userId: u.objectId });
        //       console.log('userId3:' + this.state.userId);
        //     }
        //   });
        // });

        const { formData, langFormData } = this.props;
        const changePhoneData = langFormData.changePhone || formData.changePhone;

        return (
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-12">
              <div className="d-flex justify-content-center">
                <CardBox
                  styleName="col-7"
                  cardStyle="p-5"
                  heading={
                    <IntlMessages id="pages.changephone.label.getuseroldphone" />
                  }
                >
                  <List>
                    {/* <IntlMessages id="pages.changephone.label.getuseroldphone" /> */}
                    {oldItems.map((u) => {
                      return (
                        <ListItem key={u.objectId}>
                          <ListItemIcon>
                            {oldname === u.fullname ? (
                              <i className="zmdi zmdi-circle text-green" />
                            ) : (
                              <i className="zmdi zmdi-close-circle text-red" />
                            )}
                          </ListItemIcon>
                          <ListItemText key={u.objectId}>
                            {u.fullname + " " + u.dealerCode}
                          </ListItemText>
                          <List>
                            {u.chassisNumber.map((c) => {
                              return (
                                <ListItem key={c}>
                                  <ListItemIcon>
                                    {/* {c === this.state.vinNumber ? ( */}
                                    {c === changePhoneData.vinNumber ? (  
                                      <i className="zmdi zmdi-circle text-green" />
                                    ) : (
                                      <i className="zmdi zmdi-close-circle text-red" />
                                    )}
                                  </ListItemIcon>
                                  <ListItemText key={c}>{c}</ListItemText>
                                </ListItem>
                              );
                            })}
                          </List>
                        </ListItem>
                      );
                    })}
                  </List>
                </CardBox>
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-12">
              <div className="d-flex justify-content-center">
                <CardBox
                  styleName="col-7"
                  cardStyle="p-5"
                  heading={
                    <IntlMessages id="pages.changephone.label.getuseroldphone" />
                  }
                >
                  <ListItem>
                    <ListItemIcon>
                      <i className="zmdi zmdi-close-circle text-red" />
                    </ListItemIcon>
                    <ListItemText key="1">
                      <IntlMessages id="pages.changephone.label.nooldphonedata" />
                    </ListItemText>
                  </ListItem>
                </CardBox>
              </div>
            </div>
          </div>
        );
      }
    } else {
      return null;
    }
  };

  newPhoneDisplay = (newItems) => {
    if (newItems !== undefined && newItems !== null) {
      //console.log('newItems:' + newItems.length);
      if (newItems.length > 0) {
        let newObj = newItems[0];
        if (newObj.objectId === '1') {
          return (
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                <div className="d-flex justify-content-center">
                  <CardBox
                    styleName="col-7"
                    cardStyle="p-5"
                    heading={
                      <IntlMessages id="pages.changephone.label.getusernewphone" />
                    }
                  >
                    <List>
                      {/* <IntlMessages id="pages.changephone.label.getusernewphone" /> */}
                      {newItems.map((u) => {
                        return (
                          <ListItem key={u.objectId}>
                            <ListItemIcon>
                              <i className="zmdi zmdi-close-circle text-red" />
                            </ListItemIcon>
                            <ListItemText key={u.objectId}>
                              <IntlMessages id="pages.changephone.label.newphoneisusing" />
                            </ListItemText>
                          </ListItem>
                        );
                      })}
                    </List>
                  </CardBox>
                </div>
              </div>
            </div>
          );
        } else {
          return (
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                <div className="d-flex justify-content-center">
                  <CardBox
                    styleName="col-7"
                    cardStyle="p-5"
                    heading={
                      <IntlMessages id="pages.changephone.label.getusernewphone" />
                    }
                  >
                    <List>
                      {/* <IntlMessages id="pages.changephone.label.getusernewphone" /> */}
                      {newItems.map((u) => {
                        return (
                          <ListItem key={u.objectId}>
                            <ListItemIcon>
                              <i className="zmdi zmdi-close-circle text-red" />
                            </ListItemIcon>
                            <ListItemText key={u.objectId}>
                              {u.fullname +
                                " " +
                                u.dealerCode +
                                " " +
                                u.chassisNumber}
                            </ListItemText>
                          </ListItem>
                        );
                      })}
                    </List>
                  </CardBox>
                </div>
              </div>
            </div>
          );
        }        
      } else {
        return (
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-12">
              <div className="d-flex justify-content-center">
                <CardBox
                  styleName="col-7"
                  cardStyle="p-5"
                  heading={
                    <IntlMessages id="pages.changephone.label.getusernewphone" />
                  }
                >
                  <ListItem>
                    <ListItemIcon>
                      <i className="zmdi zmdi-circle text-green" />
                    </ListItemIcon>
                    <ListItemText key="2">
                      <IntlMessages id="pages.changephone.label.nonewphonedata" />
                    </ListItemText>
                  </ListItem>
                </CardBox>
              </div>
            </div>
          </div>
        );
      }
    } else {
      return null;
    }
  };

  showUser = ({ apiUserItems, oldPhoneUser, newPhoneUser, isPhoneUsing }) => {
    //const nodata = <div></div>;
    //console.log('items2:' + apiUserItems);

    if (
      apiUserItems !== undefined &&
      apiUserItems !== null &&
      apiUserItems.CUSTOMER_NAME !== undefined
    ) {
      //console.log('Items is not null:' + apiUserItems.CUSTOMER_NAME);

      //var obj = JSON.parse(apiUserItems);
      //console.log(JSON.stringify(apiUserItems, null, "  "));
      //console.log(JSON.stringify(oldPhoneUser, null, "  "));

      //console.log('aa:' + aa.CUSTOMER_NAME);
      //const dataSet = this.dataForExportToExcel(items);
      return (
        <div>
          {/* {this.displayChangeButton(apiUserItems, oldPhoneUser, isPhoneUsing)} */}
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-12">
              <div className="d-flex justify-content-center">
                <CardBox
                  styleName="col-7"
                  cardStyle="p-5"
                  heading={
                    <IntlMessages id="pages.changephone.label.getuserfromapi" />
                  }
                >
                  {/* {apiUserItems.map(u => u.CUSTOMER_NAME)} */}

                  {this.myCarDisplay(apiUserItems)}
                </CardBox>
              </div>
            </div>
          </div>
          {/* <Divider inset='true'/> */}
          {this.oldPhoneDisplay(
            oldPhoneUser,
            apiUserItems.CUSTOMER_NAME + " " + apiUserItems.CUSTOMER_LAST_NAME
          )}
          {/* {oldPhoneUser[0].fullname} */}

          {/* <Divider inset='true'/> */}
          {this.newPhoneDisplay(newPhoneUser)}

        </div>
      );
    }
  };

  // displayChangeButton = ({ apiUserItems, oldPhoneUser, newPhoneUser }) => {
  //   if (
  //     apiUserItems !== undefined &&
  //     apiUserItems !== null &&
  //     apiUserItems.CUSTOMER_NAME !== undefined
  //   ) {
  //     if (oldPhoneUser !== undefined && oldPhoneUser !== null) {
  //       if (oldPhoneUser.length > 0) {
  //         // console.log('userId1:' + this.state.userId);

  //         oldPhoneUser.map((u) => {
  //           // console.log('userId6:' + u.objectId);
  //           // console.log('uChassis:' + u.chassisNumber);
  //           // console.log('state:' + this.state.vinNumber);
  //           u.chassisNumber.map((c) => {
  //             if (c === this.state.vinNumber) {
  //               //this.setState({ userId: u.objectId });
  //               //console.log("userId7:" + this.state.userId);
  //               return (
  //                 <div>
  //                   <div className="row">
  //                     <div className="col-xl-12 col-lg-12 col-md-12 col-12">
  //                       <div className="d-flex justify-content-center">
  //                         <CardBox styleName="col-7" cardStyle="p-5">
  //                           <Button
  //                             variant="contained"
  //                             className="jr-btn bg-green text-white"
  //                             onClick={(event) => {
  //                               if (event) event.preventDefault();
  //                             }}
  //                           >
  //                             <IntlMessages id="pages.changephone.button.change" />
  //                           </Button>
  //                         </CardBox>
  //                       </div>
  //                     </div>
  //                   </div>
  //                 </div>
  //               );
  //             }
  //           });
  //         });
  //       }
  //     }
  //   }
  // };

  displayChangeButton = ({ apiUserItems, oldPhoneUser, newPhoneUser }) => {
    //console.log('oldPhoneUser:' + JSON.stringify(oldPhoneUser));
    //console.log('newPhoneUser:' + JSON.stringify(newPhoneUser));
    if (
      oldPhoneUser !== undefined &&
      oldPhoneUser !== null &&
      newPhoneUser !== null && !newPhoneUser.length
    ) {
      if (oldPhoneUser.length > 0) {
        return (
          <div>
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                <div className="d-flex justify-content-center">
                  <CardBox styleName="col-7" cardStyle="p-5">
                    <center>
                      <Button
                        variant="contained"
                        className="jr-btn bg-green text-white"
                        onClick={(event) => {
                          if (event) event.preventDefault();

                          this.changePhonePress();
                        }}
                      >
                        <span>
                          <IntlMessages id="pages.changephone.button.change" />
                        </span>
                      </Button>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <Button
                        variant="contained"
                        className="jr-btn bg-red text-white"
                        onClick={(event) => {
                          if (event) event.preventDefault();

                          this.cancelPress();
                        }}
                      >
                        <IntlMessages id="pages.changephone.button.cancel" />
                      </Button>
                    </center>
                  </CardBox>
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  // componentDidUpdate() {
  //   //const { navigate,goBack } = this.props.navigation;

  //   if (this.props.isMessageChangePhone) {
  //     this.displayMessage(this.props.changePhoneCode);
  //   }
  // }

  render() {
    let role = localStorage.getItem("role");
    if (role !== "smt" && role !== "superAdmin") {
      if (role.length === 6) {
        return <Redirect to={"/app/dashboard/register-users"} />;
      } else {
        return <Redirect to={"/app/customers/request-delete"} />;
      }
    }

    // const formData = useSelector(state => state.changePhone.formData);
    // console.log("olePhone:" + formData.oldPhone);

    const { formData, langFormData } = this.props;

    const changePhoneData = langFormData.changePhone || formData.changePhone;

    const { oldPhone, newPhone, vinNumber, userId } = this.state;
    const {
      loaderApiUser,
      loaderUserByOldPhone,
      loaderUserByNewPhone,
      loaderChangePhone,
      apiUserItems,
      oldPhoneUser,
      newPhoneUser,
      isPhoneUsing,
      changePhoneCode,
      isMessageChangePhone,
    } = this.props;

    return (
      <div className="app-wrapper">
        <ContainerHeader
          match={this.props.match}
          title={<IntlMessages id="pages.changephone" />}
        />
        {/* <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
            sfds
        </div> */}
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-12">
            <div className="d-flex justify-content-center">
              <CardBox styleName="col-7" cardStyle="p-5">
                <form
                  id="change-phone"
                  method="post"
                  //onSubmit={this.handleSubmit.bind(this)}
                  action="/"
                >
                  <TextField
                    type="tel"
                    label={
                      <IntlMessages id="pages.changephone.textfield.oldphone" />
                    }
                    // value={this.state.oldPhone}
                    value={changePhoneData.oldPhone}
                    onChange={(event) => {
                      //this.setState({ oldPhone: event.target.value })
                      
                      // Remove non-digit characters and limit to 10 digits
                      const inputValue = event.target.value.replace(/\D/g, '').slice(0, 10);
                      //this.setState({ oldPhone: inputValue });       
                      this.props.updateFormData(
                        'changePhone',
                        'oldPhone', 
                        inputValue
                      )               
                    }}                   
                    fullWidth
                    margin="normal"
                    className="mt-0 mb-2"
                    inputProps={{ 
                      maxLength: 10,
                      pattern: "\\d{10}", // Ensure only digits are entered
                    }}
                    helperText={<IntlMessages id="pages.changephone.message.validate.phone" />}
                    error={changePhoneData.oldPhone.length > 0 && changePhoneData.oldPhone.length < 10}
                    // onInput={(e) => {
                    //   e.target.value = Math.max(0, parseInt(e.target.value))
                    //     .toString()
                    //     .slice(0, 10);
                    // }}
                  />

                  <TextField
                    type="tel"
                    label={
                      <IntlMessages id="pages.changephone.textfield.newphone" />
                    }
                    // value={this.state.newPhone}
                    value={changePhoneData.newPhone}
                    onChange={(event) => {
                      //this.setState({ newPhone: event.target.value })
                      // this.props.updateFormData(
                      //   'changePhone',
                      //   'newPhone', 
                      //   event.target.value
                      // )

                      // Remove non-digit characters and limit to 10 digits
                      const inputValue = event.target.value.replace(/\D/g, '').slice(0, 10);
                      //this.setState({ newPhone: inputValue });
                      this.props.updateFormData(
                        'changePhone',
                        'newPhone', 
                        inputValue
                      )
                    }}
                    fullWidth
                    margin="normal"
                    className="mt-0 mb-2"
                    //pattern= "\d*"
                    inputProps={{ 
                      maxLength: 10,
                      pattern: "\\d{10}", // Ensure only digits are entered
                    }}
                    helperText={<IntlMessages id="pages.changephone.message.validate.phone" />}
                    error={changePhoneData.newPhone.length > 0 && changePhoneData.newPhone.length < 10}
                  />

                  <TextField
                    type="text"
                    label={
                      <IntlMessages id="pages.changephone.textfield.vinnumber" />
                    }
                    // value={this.state.vinNumber}
                    value={changePhoneData.vinNumber}
                    onChange={(event) => {
                      this.props.updateFormData(
                        'changePhone',
                        'vinNumber', 
                        event.target.value
                      )
                      //this.setState({ vinNumber: event.target.value })
                    }}
                    fullWidth
                    margin="normal"
                    className="mt-0 mb-2"
                    inputProps={{ maxLength: 17 }}
                  />
                  <section>
                    <Button
                      variant="contained"
                      onClick={(event) => {
                        if (event) event.preventDefault();

                        // if (this.state.oldPhone === "") {
                        if (changePhoneData.oldPhone === "") {
                          NotificationManager.warning(
                            <IntlMessages id="notification.warning" />,
                            <IntlMessages id="pages.changephone.message.oldphoneempty" />,
                            3000
                          );
                          return;
                        }
                        if (changePhoneData.oldPhone.length !== 10) {
                          NotificationManager.warning(
                            <IntlMessages id="notification.warning" />,
                            <IntlMessages id="pages.changephone.message.validate.phone" />,
                            3000
                          );
                          return;
                        }
                        // if (this.state.newPhone === "") {
                        if (changePhoneData.newPhone === "") {
                          // NotificationManager.warning(
                          //   <IntlMessages id="pages.createreward.message.orderempty" />
                          // );
                          NotificationManager.warning(
                            <IntlMessages id="notification.warning" />,
                            <IntlMessages id="pages.changephone.message.newphoneempty" />,
                            3000
                          );
                          return;
                        }
                        if (changePhoneData.newPhone.length !== 10) {
                          // NotificationManager.warning(
                          //   <IntlMessages id="pages.createreward.message.orderempty" />
                          // );
                          NotificationManager.warning(
                            <IntlMessages id="notification.warning" />,
                            <IntlMessages id="pages.changephone.message.validate.phone" />,
                            3000
                          );
                          return;
                        }
                        // if (this.state.vinNumber === "") {
                        if (changePhoneData.vinNumber === "") {
                          NotificationManager.warning(
                            <IntlMessages id="notification.warning" />,
                            <IntlMessages id="pages.changephone.message.vinnumberempty" />,
                            3000
                          );
                          return;
                        }

                        this.props.showApiUserLoader();
                        this.props.getApiUserRequest(changePhoneData.newPhone, changePhoneData.vinNumber);

                        this.props.showOldPhoneLoader();
                        this.props.getUserByOldPhoneRequest(changePhoneData.oldPhone);

                        this.props.showNewPhoneLoader();
                        this.props.getUserByNewPhoneRequest(changePhoneData.newPhone);
                      }}
                      color="primary"
                    >
                      <IntlMessages id="pages.changephone.button.verify" />
                    </Button>
                    {/* {'userId:' + userId}    */}
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    {/* <Button
                      variant="contained"
                      onClick={(event) => {
                        if (event) event.preventDefault();
                        this.cancelPress();
                      }}
                      color="secondary"
                    >
                      <IntlMessages id="pages.changephone.button.reset" />
                    </Button>                     */}                    
                    <Button 
                      variant="contained"
                      onClick={this.handleReset}                      
                      color="secondary"
                    >
                      <IntlMessages id="pages.changephone.button.reset" />
                    </Button>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    {/* {localStorage.getItem('isPageNavigation')} */}
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    {/* {this.state.userId} */}
                    {/* {localStorage.getItem('changePhoneUserId')} */}
                    {/* {this.displayChangeButton(this.props)}  */}
                    {/* <Button
                      variant="contained"
                      className="jr-btn bg-green text-white"
                      onClick={(event) => {
                        if (event) event.preventDefault();
                      }}                        
                    >
                      <IntlMessages id="pages.changephone.button.change" />
                    </Button> */}
                  </section>
                </form>
              </CardBox>
            </div>
          </div>
        </div>
        {loaderApiUser && (
          <div className="loader-view">
            <CircularProgress />
          </div>
        )}
        {loaderChangePhone && (
          <div className="loader-view">
            <CircularProgress />
          </div>
        )}
        {/* {loaderChangePhone ? (
          <div className="loader-view">
            <CircularProgress />
          </div>
        ) : (
          this.displayMessage(isMessageChangePhone, changePhoneCode)
        )} */}
        <NotificationContainer />
        {/* {this.state.userId !== ''
          ? this.displayChangeButton(this.props)
          : null} */}
          {this.displayChangeButton(this.props)}
        {this.showUser(this.props)}

        {/* {isMessageChangePhone && this.displayMessage(this.props.changePhoneCode)} */}
        {/* CODE: {changePhoneCode} */}
        {/* {console.log('CHCODE:' + changePhoneCode)}
        {console.log('CHCODE2:' + this.props.changePhoneCode)} */}
        {/* { changePhoneCode === 100 && this.displaySuccessMessage() }
        { changePhoneCode === 400 && this.displayErrorMessage() } */}
        {/* { this.displaySuccessMessage(this.props.changePhoneCode)} */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  //formData: state.changePhone.formData,
  formData: state.changePhone,
  langFormData: state.settings.langFormData,
  ...(() => {
    const { changePhone, settings } = state;
    const {
      loaderApiUser,
      loaderUserByOldPhone,
      loaderUserByNewPhone,
      loaderChangePhone,
      apiUserItems,
      oldPhoneUser,
      newPhoneUser,
      isPhoneUsing,
      changePhoneCode,
      isMessageChangePhone,
    } = changePhone;
    const { locale } = settings;

    return {
      loaderApiUser,
      loaderUserByOldPhone,
      loaderUserByNewPhone,
      loaderChangePhone,
      apiUserItems,
      oldPhoneUser,
      newPhoneUser,
      isPhoneUsing,
      changePhoneCode,
      isMessageChangePhone,
      locale: settings.locale,
    };
  })()
});

// const mapStateToProps = ({ changePhone, settings }) => {

//   const {
//     loaderApiUser,
//     loaderUserByOldPhone,
//     loaderUserByNewPhone,
//     loaderChangePhone,
//     apiUserItems,
//     oldPhoneUser,
//     newPhoneUser,
//     isPhoneUsing,
//     changePhoneCode,
//     isMessageChangePhone,
//   } = changePhone;
//   const { locale } = settings;
//   return {
//     loaderApiUser,
//     loaderUserByOldPhone,
//     loaderUserByNewPhone,
//     loaderChangePhone,
//     apiUserItems,
//     oldPhoneUser,
//     newPhoneUser,
//     isPhoneUsing,
//     changePhoneCode,
//     isMessageChangePhone,
//     locale: settings.locale,
//   };
// };

export default connect(mapStateToProps, {
  getApiUserRequest,
  getUserByOldPhoneRequest,
  getUserByNewPhoneRequest,
  changePhoneRequest,
  resetChangePhone,
  showApiUserLoader,
  showOldPhoneLoader,
  showNewPhoneLoader,
  showChangePhoneLoader,
  showMessageChangePhone,
  hideMessageChangePhone,
  resetPage,
  updateFormData,
})(withTranslatedSwal(ChangePhone));

//export default ChangePhone;
