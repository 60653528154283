import { all, takeLatest, takeEvery, call, fork, put } from 'redux-saga/effects';
import {
    getCampaignInactiveItemSuccess,
    hideLoaderCampaignInactiveItem,
    getCampaignInactiveDataSuccess,
    hideLoaderCampaignInactiveData,
    exportToCSVProgress,
    exportToCSVSuccess,
    exportToCSVFailure
} from 'actions/CampaignInactiveReport';
import {
    GET_CAMPAIGN_INACTIVE_ITEM_REQUEST,
    GET_CAMPAIGN_INACTIVE_DATA_REQUEST,
    EXPORT_CAMPAIGN_INACTIVE_TO_CSV_REQUEST
} from 'constants/ActionTypes';
import { getFirestore, collection, query, where, getDoc, getDocs } from 'firebase/firestore';
import { rsf, firebase, db } from '../firebase/firebase';
import { saveAs } from 'file-saver';

// Web worker loader (configure your webpack to handle worker-loader)
//const CSVWorker = require('worker-loader!../workers/csvExport.worker.js');
//const CSVWorker = require('../workers/csvExport.worker.js');


function* getCampaignInactiveItems() {
  try {
    // const colRef = firebase.firestore().collection('DealersThailand');
    
    // const snapshot = yield call(rsf.firestore.getCollection, colRef);
   

    // Create reference to the RegisterUsers collection
    const colRef = collection(db, 'CampaignThailand');
    
    // Build query with date range
    // const q = query(
    //   colRef,
    //   where('date', '>=', startDate),
    //   where('date', '<=', endDate)
    // );

    // Build query with date range
    const q = query(
        colRef,
        where('campaignType', '==', 'CampaignInactive'),
    );

    console.log('q Inactive Items:' + q);
    
    // Fetch documents
    const snapshot = yield call(getDocs, q);

    //console.log('items:' + snapshot);
    let items = [];
    snapshot.forEach(doc => {
        //console.log(doc.data());
        var data = doc.data();
        data['id'] = data.campaignId;
        items.push(data);
        // datas = {
        //   id: doc.id,
        //   ...doc.data()
        // };
    });

    console.log('campaign Inactive items count:' + items.length);
    
    yield put(getCampaignInactiveItemSuccess(items));
  } catch (error) {
    console.log(error);
    yield put(hideLoaderCampaignInactiveItem());
  }
}

function* getCampaignInactiveData({ payload }) {

    const { campaignId } = payload;

    console.log('campaignId..:' + campaignId);

    try {
       
        // Create reference to the RegisterUsers collection
        const colRef = collection(db, 'UsersThailand');
        
        // Build query with date range
        // const q = query(
        //   colRef,
        //   where('date', '>=', startDate),
        //   where('date', '<=', endDate)
        // );
    
        // Build query with date range
        const q = query(
            colRef,
            where('campaigns', '!=', null),
        );

        console.log('q Inactive data:' + q);
        
        // Fetch documents
        const snapshot = yield call(getDocs, q);
    
        //console.log('items:' + snapshot);
        let items = [];
        snapshot.forEach(doc => {
            //console.log(doc.data());
            let data = doc.data();

            if (doc.data().campaigns != undefined) {
                for (const key in data) {
                    if (key === 'campaigns') {
                        let campaigns = data[key];

                        for (const keyItem in campaigns) {
                          console.log('keyItem:' + keyItem + ' campaignId:' + JSON.stringify(campaignId));
                            if (keyItem === campaignId) {
                                let campaignItem = campaigns[keyItem];
                                campaignItem.createdAt = data.createdAt;
                                campaignItem.firstname = data.firstname;
                                campaignItem.lastname = data.lastname;     
                                campaignItem.dealerCode = data.dealerCode;
                                campaignItem.dealerNameEn = data.dealerNameEn;
                                campaignItem.dealerNameTh = data.dealerNameTh;
                                campaignItem.isPdpaConsent = data.isPdpaConsent;
                                campaignItem.lastOnline = data.lastOnline;
                                campaignItem.lastPointExpiredDate = data.lastPointExpiredDate;
                                campaignItem.nextPointExpiredDate = data.nextPointExpiredDate;
                                let vinNumber = data.chassisNumber;
                                
                                if (data.chassisNumber.length > 1) {
                                    for (let i=1; i < data.chassisNumber.length; i++) {
                                        vinNumber = ', ' + data.chassisNumber;
                                    }
                                } 
                                campaignItem.vinNumber = vinNumber;
                                items.push(campaignItem);
                            }
                        }
                    }
                }
            }


            //data['id'] = data.campaignId;
            //items.push(data);
            // datas = {
            //   id: doc.id,
            //   ...doc.data()
            // };
        });

        console.log('campaign Inactive data count:' + items.length);
        
        yield put(getCampaignInactiveDataSuccess(items));
      } catch (error) {
        console.log(error);
        yield put(hideLoaderCampaignInactiveData());
      }
}

// Helper function to convert data to CSV format
function convertToCSV(data, headersCustom) { //language
  if (!data || !data.length) return '';
  
  // Extract column headers from first row
  const headers = Object.keys(data[0]);  
  
  // Create CSV header row
  const csvHeader = headers.join(',');
  
  // Process data in chunks to handle large datasets efficiently
  const chunkSize = 1000;
  let csvBody = '';

  //const headersCustom = ['หมายเลขตรวจถัง', 'ชื่อ', 'นามสกุล', 'รหัสตัวแทน', 'อนุญาต PDPA', 'วันที่ลงทะเบียน', 'วันที่ใช้งานล่าสุด', 'กดรับคะแนน', 'คะแนนก่อนแคมเปญ', 'คะแนนแคมเปญ', 'คะแนนรวมหลังแคมเปญ', 'กดรับคะแนนวันที่', 'กดรับคะแนนเวลา'];
  const csvHeaderCustom = headersCustom.join(',');

  //csvBody += headersCustom.join(',');
  //csvBody += '\n';
  
  for (let i = 0; i < data.length; i += chunkSize) {
    const chunk = data.slice(i, i + chunkSize);
    
    // Convert each row to CSV format
    const chunkRows = chunk.map(row => {
      return headers.map(header => {
        // Handle special cases (commas, quotes, null values)
        const cell = row[header] === null || row[header] === undefined ? '' : row[header];
        const cellStr = String(cell);
        
        // Escape quotes and wrap in quotes if contains commas, quotes or newlines
        if (cellStr.includes(',') || cellStr.includes('"') || cellStr.includes('\n')) {
          return `"${cellStr.replace(/"/g, '""')}"`;
        }
        return cellStr;
      }).join(',');
    }).join('\n');
    
    csvBody += chunkRows + (i + chunkSize < data.length ? '\n' : '');
  }
  
  //return `${csvHeader}\n${csvBody}`;
  return `${csvHeaderCustom}\n${csvBody}`;
}

// Worker saga for CSV export
function* exportCsvWorker({ payload }) {
  try {
    const { data, fileName, headers } = payload; //action.payload;

    console.log('data count:' + data.length);
    console.log('filename:' + fileName);
    console.log('headers:' + headers);
    
    // Convert data to CSV
    const csv = yield call(convertToCSV, data, headers);
    
    // Create blob and trigger download
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
    
    yield call(saveAs, blob, fileName);
    
    yield put(exportToCSVSuccess());
  } catch (error) {
    yield put(exportToCSVFailure(error.message));
  }
}

export function* watchCampaignInactiveItemsRequest() {
  yield takeLatest(GET_CAMPAIGN_INACTIVE_ITEM_REQUEST, getCampaignInactiveItems);
}
export function* watchCampaignInactiveDataRequest() {
  yield takeLatest(GET_CAMPAIGN_INACTIVE_DATA_REQUEST, getCampaignInactiveData);
}
export function* watchCampaignInactiveHandleExportToCSV() {
  yield takeLatest(EXPORT_CAMPAIGN_INACTIVE_TO_CSV_REQUEST, exportCsvWorker);
}


export default function* rootSaga() {
  yield all([
    fork(watchCampaignInactiveItemsRequest),
    fork(watchCampaignInactiveDataRequest),
    fork(watchCampaignInactiveHandleExportToCSV),
  ]);
}