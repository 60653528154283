import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';

import IntlMessages from 'util/IntlMessages';
import CustomScrollbars from 'util/CustomScrollbars';

class SidenavContent extends Component {
  componentDidMount() {
    const { history } = this.props;
    const that = this;
    const pathname = `${history.location.pathname}`; // get current path

    //console.log('ROLE:' + localStorage.getItem('role'));

    const menuLi = document.getElementsByClassName('menu');
    for (let i = 0; i < menuLi.length; i++) {
      menuLi[i].onclick = function(event) {
        const parentLiEle = that.closest(this, 'li');
        if (menuLi[i].classList.contains('menu') && parentLiEle !== null) {
          event.stopPropagation();

          if (menuLi[i].classList.contains('open')) {
            menuLi[i].classList.remove('open', 'active');
          } else {
            menuLi[i].classList.add('open', 'active');
          }
        } else {
          for (let j = 0; j < menuLi.length; j++) {
            const parentLi = that.closest(this, 'li');
            if (
              menuLi[j] !== this &&
              (parentLi === null || !parentLi.classList.contains('open'))
            ) {
              menuLi[j].classList.remove('open');
            } else {
              if (menuLi[j].classList.contains('open')) {
                menuLi[j].classList.remove('open');
              } else {
                menuLi[j].classList.add('open');
              }
            }
          }
        }
      };
    }

    const activeLi = document.querySelector('a[href="' + pathname + '"]'); // select current a element
    try {
      const activeNav = this.closest(activeLi, 'ul'); // select closest ul
      if (activeNav.classList.contains('sub-menu')) {
        this.closest(activeNav, 'li').classList.add('open');
      } else {
        this.closest(activeLi, 'li').classList.add('open');
      }
    } catch (error) {}
  }

  componentWillReceiveProps(nextProps) {
    const { history } = nextProps;
    const pathname = `${history.location.pathname}`; // get current path

    const activeLi = document.querySelector('a[href="' + pathname + '"]'); // select current a element
    try {
      const activeNav = this.closest(activeLi, 'ul'); // select closest ul
      if (activeNav.classList.contains('sub-menu')) {
        this.closest(activeNav, 'li').classList.add('open');
      } else {
        this.closest(activeLi, 'li').classList.add('open');
      }
    } catch (error) {}
  }

  closest(el, selector) {
    try {
      let matchesFn;
      // find vendor prefix
      [
        'matches',
        'webkitMatchesSelector',
        'mozMatchesSelector',
        'msMatchesSelector',
        'oMatchesSelector'
      ].some(function(fn) {
        if (typeof document.body[fn] === 'function') {
          matchesFn = fn;
          return true;
        }
        return false;
      });

      let parent;

      // traverse parents
      while (el) {
        parent = el.parentElement;
        if (parent && parent[matchesFn](selector)) {
          return parent;
        }
        el = parent;
      }
    } catch (e) {}

    return null;
  }

  displaySideNavByRole = () => {
    var role = localStorage.getItem('role'); // smt, superAdmin, userHaveCar, userNoCar, 900010
    if (role === 'superAdmin') {
      return (
        <CustomScrollbars className=" scrollbar">
          <ul className="nav-menu">
            <li className="nav-header">
              <IntlMessages id="sidebar.main" />
            </li>
            {/* <li className="menu no-arrow">
              <NavLink to="/app/sample-page">
                <i className="zmdi zmdi-view-dashboard zmdi-hc-fw"/>
                <span className="nav-text"><IntlMessages id="pages.samplePage"/> </span>
              </NavLink>
            </li> */}            
            <li className="menu no-arrow">
              <NavLink to="/app/dashboard">
                <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
                <span className="nav-text">
                  <IntlMessages id="sidebar.dashboard" />{' '}
                </span>
              </NavLink>
            </li>
            <li className="menu no-arrow">
              <NavLink to="/app/analytics">
                <i className="zmdi zmdi-trending-up zmdi-hc-fw" />
                <span className="nav-text">
                  <IntlMessages id="sidebar.analytics" />{' '}
                </span>
              </NavLink>
            </li>
            <li className="menu collapse-box">
              <Button>
                <i className="zmdi zmdi-device-hub zmdi-hc-fw" />
                <span className="nav-text">
                  <IntlMessages id="sidebar.dealers" />
                </span>
              </Button>
              <ul className="sub-menu">
                <li>
                  <NavLink
                    className="prepend-icon"
                    to="/app/dealers/add-dealer-data"
                  >
                    <span className="nav-text">
                      <IntlMessages id="sidebar.dealers.adddealerdata" />
                    </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="prepend-icon"
                    to="/app/dealers/create-dealer-account"
                  >
                    <span className="nav-text">
                      <IntlMessages id="sidebar.dealers.createdealeraccount" />
                    </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="prepend-icon"
                    to="/app/dealers/dealer-list"
                  >
                    <span className="nav-text">
                      <IntlMessages id="sidebar.dealers.dealerlist" />
                    </span>
                  </NavLink>
                </li>
              </ul>
            </li>
            <li className="menu collapse-box">
              <Button>
                <i className="zmdi zmdi-account-box zmdi-hc-fw" />
                <span className="nav-text">
                  <IntlMessages id="sidebar.users" />
                </span>
              </Button>
              <ul className="sub-menu">
                <li>
                  <NavLink className="prepend-icon" to="/app/users/user-list">
                    <span className="nav-text">
                      <IntlMessages id="sidebar.users.userlist" />
                    </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="prepend-icon"
                    to="/app/users/user-list-photo"
                  >
                    <span className="nav-text">
                      <IntlMessages id="sidebar.users.userlistphoto" />
                    </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink className="prepend-icon" to="/app/users/dealers">
                    <span className="nav-text">
                      <IntlMessages id="sidebar.users.usersbydealer" />
                    </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="prepend-icon"
                    to="/app/users/friendsdealers"
                  >
                    <span className="nav-text">
                      <IntlMessages id="sidebar.users.friendsbydealer" />
                    </span>
                  </NavLink>
                </li>                
                <li>
                  <NavLink
                    className="prepend-icon"
                    to="/app/users/user-change-phone"
                  >
                    <span className="nav-text">
                      <IntlMessages id="sidebar.users.changephone" />
                    </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="prepend-icon"
                    to="/app/users/second-hand-car"
                  >
                    <span className="nav-text">
                      <IntlMessages id="sidebar.users.secondhandcar" />
                    </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="prepend-icon"
                    to="/app/users/search-user"
                  >
                    <span className="nav-text">
                      <IntlMessages id="sidebar.users.searchuser" />
                    </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="prepend-icon"
                    to="/app/users/active-user"
                  >
                    <span className="nav-text">
                      <IntlMessages id="sidebar.users.activeuser" />
                    </span>
                  </NavLink>
                </li>
              </ul>
            </li>
            <li className="menu collapse-box">
              <Button>
                <i className="zmdi zmdi-star zmdi-hc-fw" />
                <span className="nav-text">
                  <IntlMessages id="sidebar.privilege" />
                </span>
              </Button>
              <ul className="sub-menu">
                <li>
                  <NavLink
                    className="prepend-icon"
                    to="/app/privilege/create-reward"
                  >
                    <span className="nav-text">
                      <IntlMessages id="sidebar.privilege.createreward" />
                    </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="prepend-icon"
                    to="/app/privilege/reward-list"
                  >
                    <span className="nav-text">
                      <IntlMessages id="sidebar.privilege.rewardlist" />
                    </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="prepend-icon"
                    to="/app/privilege/redeem-history"
                  >
                    <span className="nav-text">
                      <IntlMessages id="sidebar.privilege.redeemhistory" />
                    </span>
                  </NavLink>
                </li>
              </ul>
            </li>
            <li className="menu collapse-box">
              <Button>
                <i className="zmdi zmdi-assignment zmdi-hc-fw" />
                <span className="nav-text">
                  <IntlMessages id="sidebar.service.survey" />
                </span>
              </Button>
              <ul className="sub-menu">
                <li>
                  <NavLink
                    className="prepend-icon"
                    to="/app/service-survey/dashboard"
                  >
                    <span className="nav-text">
                      <IntlMessages id="sidebar.service.survey.dashboard" />
                    </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="prepend-icon"
                    to="/app/service-survey/statistics"
                  >
                    <span className="nav-text">
                      <IntlMessages id="sidebar.service.survey.statistics" />
                    </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="prepend-icon"
                    to="/app/service-survey/statistics-by-date"
                  >
                    <span className="nav-text">
                      <IntlMessages id="sidebar.service.survey.statisticsbydate" />
                    </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="prepend-icon"
                    to="/app/service-survey/ranking"
                  >
                    <span className="nav-text">
                      <IntlMessages id="sidebar.service.survey.ranking" />
                    </span>
                  </NavLink>
                </li>
              </ul>
            </li>
            {/* https://fonts.thembay.com/material-design-iconic/ zmdi zmdi-graphic-eq zmdi zmdi-hearing zmdi zmdi-surround-sound zmdi zmdi-rss */}
            <li className="menu collapse-box">
              <Button>
                <i className="zmdi zmdi-input-antenna zmdi-hc-fw" />
                <span className="nav-text">
                  <IntlMessages id="sidebar.campaign" />
                </span>
              </Button>
              <ul className="sub-menu">
                <li>
                  <NavLink
                    className="prepend-icon"
                    to="/app/campaign/inactive"
                  >
                    <span className="nav-text">
                      <IntlMessages id="sidebar.campaign.inactive" />
                    </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="prepend-icon"
                    to="/app/campaign/inactivereport"
                  >
                    <span className="nav-text">
                      <IntlMessages id="sidebar.campaign.inactive.report" />
                    </span>
                  </NavLink>
                </li>   
                <li>
                  <NavLink
                    className="prepend-icon"
                    to="/app/campaign/rank"
                  >
                    <span className="nav-text">
                      <IntlMessages id="sidebar.campaign.rank" />
                    </span>
                  </NavLink>
                </li>      
                <li>
                  <NavLink
                    className="prepend-icon"
                    to="/app/campaign/rankreport"
                  >
                    <span className="nav-text">
                      <IntlMessages id="sidebar.campaign.rank.report" />
                    </span>
                  </NavLink>
                </li>             
              </ul>
            </li>
            <li className="menu no-arrow">
              <NavLink to="/app/chat-history">
                <i className="zmdi zmdi-comment zmdi-hc-fw" />
                <span className="nav-text">
                  <IntlMessages id="sidebar.chat.history" />{' '}
                </span>
              </NavLink>
            </li>
            <li className="menu no-arrow">
              <NavLink to="/app/contactUs">
                <i className="zmdi zmdi-email zmdi-hc-fw" />
                <span className="nav-text">
                  <IntlMessages id="sidebar.contactus" />{' '}
                </span>
              </NavLink>
            </li>
          </ul>
        </CustomScrollbars>
      );
    } else if (role === 'smt') {
      return (
        <CustomScrollbars className=" scrollbar">
          <ul className="nav-menu">
            <li className="nav-header">
              <IntlMessages id="sidebar.main" />
            </li>
            {/* <li className="menu no-arrow">
              <NavLink to="/app/sample-page">
                <i className="zmdi zmdi-view-dashboard zmdi-hc-fw"/>
                <span className="nav-text"><IntlMessages id="pages.samplePage"/> </span>
              </NavLink>
            </li> */}
            <li className="menu no-arrow">
              <NavLink to="/app/dashboard">
                <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
                <span className="nav-text">
                  <IntlMessages id="sidebar.dashboard" />{' '}
                </span>
              </NavLink>
            </li>
            <li className="menu no-arrow">
              <NavLink to="/app/analytics">
                <i className="zmdi zmdi-trending-up zmdi-hc-fw" />
                <span className="nav-text">
                  <IntlMessages id="sidebar.analytics" />{' '}
                </span>
              </NavLink>
            </li>
            <li className="menu collapse-box">
              <Button>
                <i className="zmdi zmdi-device-hub zmdi-hc-fw" />
                <span className="nav-text">
                  <IntlMessages id="sidebar.dealers" />
                </span>
              </Button>
              <ul className="sub-menu">
                {/* <li>
                  <NavLink
                    className="prepend-icon"
                    to="/app/dealers/add-dealer-data"
                  >
                    <span className="nav-text">
                      <IntlMessages id="sidebar.dealers.adddealerdata" />
                    </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="prepend-icon"
                    to="/app/dealers/create-dealer-account"
                  >
                    <span className="nav-text">
                      <IntlMessages id="sidebar.dealers.createdealeraccount" />
                    </span>
                  </NavLink>
                </li> */}
                <li>
                  <NavLink
                    className="prepend-icon"
                    to="/app/dealers/dealer-list"
                  >
                    <span className="nav-text">
                      <IntlMessages id="sidebar.dealers.dealerlist" />
                    </span>
                  </NavLink>
                </li>
              </ul>
            </li>
            <li className="menu collapse-box">
              <Button>
                <i className="zmdi zmdi-account-box zmdi-hc-fw" />
                <span className="nav-text">
                  <IntlMessages id="sidebar.users" />
                </span>
              </Button>
              <ul className="sub-menu">
                <li>
                  <NavLink className="prepend-icon" to="/app/users/user-list">
                    <span className="nav-text">
                      <IntlMessages id="sidebar.users.userlist" />
                    </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink className="prepend-icon" to="/app/users/dealers">
                    <span className="nav-text">
                      <IntlMessages id="sidebar.users.usersbydealer" />
                    </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="prepend-icon"
                    to="/app/users/user-change-phone"
                  >
                    <span className="nav-text">
                      <IntlMessages id="sidebar.users.changephone" />
                    </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="prepend-icon"
                    to="/app/users/second-hand-car"
                  >
                    <span className="nav-text">
                      <IntlMessages id="sidebar.users.secondhandcar" />
                    </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="prepend-icon"
                    to="/app/users/search-user"
                  >
                    <span className="nav-text">
                      <IntlMessages id="sidebar.users.searchuser" />
                    </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="prepend-icon"
                    to="/app/users/active-user"
                  >
                    <span className="nav-text">
                      <IntlMessages id="sidebar.users.activeuser" />
                    </span>
                  </NavLink>
                </li>
              </ul>
            </li>
            <li className="menu collapse-box">
              <Button>
                <i className="zmdi zmdi-star zmdi-hc-fw" />
                <span className="nav-text">
                  <IntlMessages id="sidebar.privilege" />
                </span>
              </Button>
              <ul className="sub-menu">
                <li>
                  <NavLink
                    className="prepend-icon"
                    to="/app/privilege/create-reward"
                  >
                    <span className="nav-text">
                      <IntlMessages id="sidebar.privilege.createreward" />
                    </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="prepend-icon"
                    to="/app/privilege/reward-list"
                  >
                    <span className="nav-text">
                      <IntlMessages id="sidebar.privilege.rewardlist" />
                    </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="prepend-icon"
                    to="/app/privilege/redeem-history"
                  >
                    <span className="nav-text">
                      <IntlMessages id="sidebar.privilege.redeemhistory" />
                    </span>
                  </NavLink>
                </li>
              </ul>
            </li>
            <li className="menu collapse-box">
              <Button>
                <i className="zmdi zmdi-assignment zmdi-hc-fw" />
                <span className="nav-text">
                  <IntlMessages id="sidebar.service.survey" />
                </span>
              </Button>
              <ul className="sub-menu">
                <li>
                  <NavLink
                    className="prepend-icon"
                    to="/app/service-survey/dashboard"
                  >
                    <span className="nav-text">
                      <IntlMessages id="sidebar.service.survey.dashboard" />
                    </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="prepend-icon"
                    to="/app/service-survey/statistics"
                  >
                    <span className="nav-text">
                      <IntlMessages id="sidebar.service.survey.statistics" />
                    </span>
                  </NavLink>
                </li>
                {/* <li>
                  <NavLink
                    className="prepend-icon"
                    to="/app/service-survey/statistics-by-date"
                  >
                    <span className="nav-text">
                      <IntlMessages id="sidebar.service.survey.statisticsbydate" />
                    </span>
                  </NavLink>
                </li> */}
                <li>
                  <NavLink
                    className="prepend-icon"
                    to="/app/service-survey/ranking"
                  >
                    <span className="nav-text">
                      <IntlMessages id="sidebar.service.survey.ranking" />
                    </span>
                  </NavLink>
                </li>
              </ul>
            </li>
            {/* 
            zmdi-spinner 
            zmdi-fire
            zmdi-flare
            zmdi-portable-wifi
            */}
            <li className="menu collapse-box">
              <Button>
                <i className="zmdi zmdi-fire zmdi-hc-fw" />
                <span className="nav-text">
                  <IntlMessages id="sidebar.campaign" />
                </span>
              </Button>
              <ul className="sub-menu">
              <li>
                  <NavLink
                    className="prepend-icon"
                    to="/app/campaign/inactive"
                  >
                    <span className="nav-text">
                      <IntlMessages id="sidebar.campaign.inactive" />
                    </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="prepend-icon"
                    to="/app/campaign/inactivereport"
                  >
                    <span className="nav-text">
                      <IntlMessages id="sidebar.campaign.inactive.report" />
                    </span>
                  </NavLink>
                </li>   
                <li>
                  <NavLink
                    className="prepend-icon"
                    to="/app/campaign/rank"
                  >
                    <span className="nav-text">
                      <IntlMessages id="sidebar.campaign.rank" />
                    </span>
                  </NavLink>
                </li>      
                <li>
                  <NavLink
                    className="prepend-icon"
                    to="/app/campaign/rankreport"
                  >
                    <span className="nav-text">
                      <IntlMessages id="sidebar.campaign.rank.report" />
                    </span>
                  </NavLink>
                </li>                     
              </ul>
            </li>
            <li className="menu no-arrow">
              <NavLink to="/app/chat-history">
                <i className="zmdi zmdi-comment zmdi-hc-fw" />
                <span className="nav-text">
                  <IntlMessages id="sidebar.chat.history" />{' '}
                </span>
              </NavLink>
            </li>
            <li className="menu no-arrow">
              <NavLink to="/app/contactUs">
                <i className="zmdi zmdi-email zmdi-hc-fw" />
                <span className="nav-text">
                  <IntlMessages id="sidebar.contactus" />{' '}
                </span>
              </NavLink>
            </li>
          </ul>
        </CustomScrollbars>
      );
    } else if (role.length === 6) {
      //Dealer
      return (
        <CustomScrollbars className=" scrollbar">
          <ul className="nav-menu">
            <li className="nav-header">
              <IntlMessages id="sidebar.main" />
            </li>
            {/* <li className="menu no-arrow">
              <NavLink to="/app/sample-page">
                <i className="zmdi zmdi-view-dashboard zmdi-hc-fw"/>
                <span className="nav-text"><IntlMessages id="pages.samplePage"/> </span>
              </NavLink>
            </li> */}
            <li className="menu no-arrow">
              <NavLink to="/app/dashboard">
                <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
                <span className="nav-text">
                  <IntlMessages id="sidebar.dashboard" />{' '}
                </span>
              </NavLink>
            </li>
            {/* <li className="menu collapse-box">
              <Button>
                <i className="zmdi zmdi-device-hub zmdi-hc-fw" />
                <span className="nav-text">
                  <IntlMessages id="sidebar.dealers" />
                </span>
              </Button>
              <ul className="sub-menu">
                <li>
                  <NavLink
                    className="prepend-icon"
                    to="/app/dealers/dealer-list"
                  >
                    <span className="nav-text">
                      <IntlMessages id="sidebar.dealers.dealerlist" />
                    </span>
                  </NavLink>
                </li>
              </ul>
            </li> */}
            <li className="menu collapse-box">
              <Button>
                <i className="zmdi zmdi-account-box zmdi-hc-fw" />
                <span className="nav-text">
                  <IntlMessages id="sidebar.users" />
                </span>
              </Button>
              <ul className="sub-menu">
                {/* <li>
                  <NavLink className="prepend-icon" to="/app/users/user-list">
                    <span className="nav-text">
                      <IntlMessages id="sidebar.users.userlist" />
                    </span>
                  </NavLink>
                </li> */}
                {/* <li>
                  <NavLink className="prepend-icon" to="/app/users/dealers">
                    <span className="nav-text">
                      <IntlMessages id="sidebar.users.usersbydealer" />
                    </span>
                  </NavLink>
                </li> */}
                <li>
                  <NavLink
                    className="prepend-icon"
                    to={`/app/users/dealers/users-by-default-dealer/${localStorage.getItem(
                      'role'
                    )}`}
                  >
                    <span className="nav-text">
                      <IntlMessages id="sidebar.users.customersbydealer" />
                    </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="prepend-icon"
                    to={`/app/users/dealers/users-by-dealer/${localStorage.getItem(
                      'role'
                    )}`}
                  >
                    <span className="nav-text">
                      <IntlMessages id="sidebar.users.friendsbydealer" />
                    </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="prepend-icon"
                    to="/app/users/search-user"
                  >
                    <span className="nav-text">
                      <IntlMessages id="sidebar.users.searchuser" />
                    </span>
                  </NavLink>
                </li>
                {/* <li>
                  <NavLink
                    className="prepend-icon"
                    to="/app/users/friendsdealers"
                  >
                    <span className="nav-text">
                      <IntlMessages id="sidebar.users.friendsbydealer" />
                    </span>
                  </NavLink>
                </li> */}
              </ul>
            </li>
            <li className="menu collapse-box">
              <Button>
                <i className="zmdi zmdi-star zmdi-hc-fw" />
                <span className="nav-text">
                  <IntlMessages id="sidebar.privilege" />
                </span>
              </Button>
              <ul className="sub-menu">
                {/* <li>
                  <NavLink
                    className="prepend-icon"
                    to="/app/privilege/create-reward"
                  >
                    <span className="nav-text">
                      <IntlMessages id="sidebar.privilege.createreward" />
                    </span>
                  </NavLink>
                </li> */}
                <li>
                  <NavLink
                    className="prepend-icon"
                    to="/app/privilege/reward-list"
                  >
                    <span className="nav-text">
                      <IntlMessages id="sidebar.privilege.rewardlist" />
                    </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="prepend-icon"
                    to="/app/privilege/redeem-history-by-dealer"
                  >
                    <span className="nav-text">
                      <IntlMessages id="sidebar.privilege.redeemhistory" />
                    </span>
                  </NavLink>
                </li>
              </ul>
            </li>
            <li className="menu collapse-box">
              <Button>
                <i className="zmdi zmdi-assignment zmdi-hc-fw" />
                <span className="nav-text">
                  <IntlMessages id="sidebar.service.survey" />
                </span>
              </Button>
              <ul className="sub-menu">
                <li>
                  <NavLink
                    className="prepend-icon"
                    to="/app/service-survey/dashboard-by-dealer"
                  >
                    <span className="nav-text">
                      <IntlMessages id="sidebar.service.survey.dashboard" />
                    </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="prepend-icon"
                    to="/app/service-survey/statistics-by-dealer"
                  >
                    <span className="nav-text">
                      <IntlMessages id="sidebar.service.survey.statistics" />
                    </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="prepend-icon"
                    to="/app/service-survey/ranking"
                  >
                    <span className="nav-text">
                      <IntlMessages id="sidebar.service.survey.ranking" />
                    </span>
                  </NavLink>
                </li>
              </ul>
            </li>
            <li className="menu no-arrow">
              <NavLink
                to={`/app/chat-history/dealers/users-by-dealer/${localStorage.getItem(
                  'role'
                )}`}
              >
                <i className="zmdi zmdi-comment zmdi-hc-fw" />
                <span className="nav-text">
                  <IntlMessages id="sidebar.chat.history" />{' '}
                </span>
              </NavLink>
            </li>
            {/* <li className="menu no-arrow">
              <NavLink to="/app/chat-history">
                <i className="zmdi zmdi-comment zmdi-hc-fw" />
                <span className="nav-text">
                  <IntlMessages id="sidebar.chat.history" />{' '}
                </span>
              </NavLink>
            </li> */}
          </ul>
        </CustomScrollbars>
      );
    } else if (role === 'userHaveCar') {
      return (
        <CustomScrollbars className=" scrollbar">
          <ul className="nav-menu">
            <li className="nav-header">
              <IntlMessages id="sidebar.main" />
            </li>
            <li className="menu no-arrow">
              <NavLink to="/app/customers/request-delete">
                <i className="zmdi zmdi-delete zmdi-hc-fw" />
                <span className="nav-text">
                  <IntlMessages id="sidebar.users.requestdelete" />{' '}
                </span>
              </NavLink>
            </li>
            <li className="menu no-arrow">
              <NavLink to="/app/customers/terms-and-policy">
                <i className="zmdi zmdi-assignment zmdi-hc-fw" />
                <span className="nav-text">
                  <IntlMessages id="sidebar.users.termsandpolicy" />{' '}
                </span>
              </NavLink>
            </li>
          </ul>
        </CustomScrollbars>
      );
    } else if (role === 'userNoCar') {
      return (
        <CustomScrollbars className=" scrollbar">
          <ul className="nav-menu">
            <li className="nav-header">
              <IntlMessages id="sidebar.main" />
            </li>
            <li className="menu no-arrow">
              <NavLink to="/app/customers/request-delete">
                <i className="zmdi zmdi-delete zmdi-hc-fw" />
                <span className="nav-text">
                  <IntlMessages id="sidebar.users.requestdelete" />{' '}
                </span>
              </NavLink>
            </li>
            <li className="menu no-arrow">
              <NavLink to="/app/customers/terms-and-policy">
                <i className="zmdi zmdi-assignment zmdi-hc-fw" />
                <span className="nav-text">
                  <IntlMessages id="sidebar.users.termsandpolicy" />{' '}
                </span>
              </NavLink>
            </li>
          </ul>
        </CustomScrollbars>
      );
    } else {
      return (
        <CustomScrollbars className=" scrollbar">
          <ul className="nav-menu">
            <li className="nav-header">
              <IntlMessages id="sidebar.main" />
            </li>
            <li className="menu no-arrow">
              <NavLink to="/app/customers/request-delete">
                <i className="zmdi zmdi-delete zmdi-hc-fw" />
                <span className="nav-text">
                  <IntlMessages id="sidebar.users.requestdelete" />{' '}
                </span>
              </NavLink>
            </li>
            <li className="menu no-arrow">
              <NavLink to="/app/customers/terms-and-policy">
                <i className="zmdi zmdi-assignment zmdi-hc-fw" />
                <span className="nav-text">
                  <IntlMessages id="sidebar.users.termsandpolicy" />{' '}
                </span>
              </NavLink>
            </li>
          </ul>
        </CustomScrollbars>
      );
    }
  };

  render() {
    return this.displaySideNavByRole();
    // <CustomScrollbars className=" scrollbar">
    //   <ul className="nav-menu">
    //     <li className="nav-header">
    //       <IntlMessages id="sidebar.main" />
    //     </li>
    //     {/* <li className="menu no-arrow">
    //       <NavLink to="/app/sample-page">
    //         <i className="zmdi zmdi-view-dashboard zmdi-hc-fw"/>
    //         <span className="nav-text"><IntlMessages id="pages.samplePage"/> </span>
    //       </NavLink>
    //     </li> */}
    //     <li className="menu no-arrow">
    //       <NavLink to="/app/dashboard">
    //         <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
    //         <span className="nav-text">
    //           <IntlMessages id="sidebar.dashboard" />{' '}
    //         </span>
    //       </NavLink>
    //     </li>
    //     <li className="menu collapse-box">
    //       <Button>
    //         <i className="zmdi zmdi-device-hub zmdi-hc-fw" />
    //         <span className="nav-text">
    //           <IntlMessages id="sidebar.dealers" />
    //         </span>
    //       </Button>
    //       <ul className="sub-menu">
    //         <li>
    //           <NavLink
    //             className="prepend-icon"
    //             to="/app/dealers/add-dealer-data"
    //           >
    //             <span className="nav-text">
    //               <IntlMessages id="sidebar.dealers.adddealerdata" />
    //             </span>
    //           </NavLink>
    //         </li>
    //         <li>
    //           <NavLink
    //             className="prepend-icon"
    //             to="/app/dealers/create-dealer-account"
    //           >
    //             <span className="nav-text">
    //               <IntlMessages id="sidebar.dealers.createdealeraccount" />
    //             </span>
    //           </NavLink>
    //         </li>
    //         <li>
    //           <NavLink className="prepend-icon" to="/app/dealers/dealer-list">
    //             <span className="nav-text">
    //               <IntlMessages id="sidebar.dealers.dealerlist" />
    //             </span>
    //           </NavLink>
    //         </li>
    //       </ul>
    //     </li>
    //     <li className="menu collapse-box">
    //       <Button>
    //         <i className="zmdi zmdi-account-box zmdi-hc-fw" />
    //         <span className="nav-text">
    //           <IntlMessages id="sidebar.users" />
    //         </span>
    //       </Button>
    //       <ul className="sub-menu">
    //         <li>
    //           <NavLink className="prepend-icon" to="/app/users/user-list">
    //             <span className="nav-text">
    //               <IntlMessages id="sidebar.users.userlist" />
    //             </span>
    //           </NavLink>
    //         </li>
    //         <li>
    //           <NavLink className="prepend-icon" to="/app/users/dealers">
    //             <span className="nav-text">
    //               <IntlMessages id="sidebar.users.usersbydealer" />
    //             </span>
    //           </NavLink>
    //         </li>
    //         <li>
    //           <NavLink
    //             className="prepend-icon"
    //             to="/app/users/friendsdealers"
    //           >
    //             <span className="nav-text">
    //               <IntlMessages id="sidebar.users.friendsbydealer" />
    //             </span>
    //           </NavLink>
    //         </li>
    //       </ul>
    //     </li>
    //     <li className="menu collapse-box">
    //       <Button>
    //         <i className="zmdi zmdi-star zmdi-hc-fw" />
    //         <span className="nav-text">
    //           <IntlMessages id="sidebar.privilege" />
    //         </span>
    //       </Button>
    //       <ul className="sub-menu">
    //         <li>
    //           <NavLink
    //             className="prepend-icon"
    //             to="/app/dealers/add-dealer-data"
    //           >
    //             <span className="nav-text">
    //               <IntlMessages id="sidebar.privilege.createreward" />
    //             </span>
    //           </NavLink>
    //         </li>
    //         <li>
    //           <NavLink
    //             className="prepend-icon"
    //             to="/app/dealers/create-dealer-account"
    //           >
    //             <span className="nav-text">
    //               <IntlMessages id="sidebar.privilege.rewardlist" />
    //             </span>
    //           </NavLink>
    //         </li>
    //         <li>
    //           <NavLink className="prepend-icon" to="/app/dealers/dealer-list">
    //             <span className="nav-text">
    //               <IntlMessages id="sidebar.privilege.redeemhistory" />
    //             </span>
    //           </NavLink>
    //         </li>
    //       </ul>
    //     </li>
    //     <li className="menu collapse-box">
    //       <Button>
    //         <i className="zmdi zmdi-assignment zmdi-hc-fw" />
    //         <span className="nav-text">
    //           <IntlMessages id="sidebar.service.survey" />
    //         </span>
    //       </Button>
    //       <ul className="sub-menu">
    //         <li>
    //           <NavLink
    //             className="prepend-icon"
    //             to="/app/service-survey/dashboard"
    //           >
    //             <span className="nav-text">
    //               <IntlMessages id="sidebar.service.survey.dashboard" />
    //             </span>
    //           </NavLink>
    //         </li>
    //       </ul>
    //     </li>
    //     <li className="menu no-arrow">
    //       <NavLink to="/app/chat-history">
    //         <i className="zmdi zmdi-comment zmdi-hc-fw" />
    //         <span className="nav-text">
    //           <IntlMessages id="sidebar.chat.history" />{' '}
    //         </span>
    //       </NavLink>
    //     </li>
    //   </ul>
    // </CustomScrollbars>
  }
}

export default withRouter(SidenavContent);

//https://iconify.design/icon-sets/zmdi/
/*


superAdmin, smt, dealer
/app/dashboard

superAdmin, smt
/app/analytics

superAdmin
/app/dealers/add-dealer-data

superAdmin
/app/dealers/create-dealer-account

superAdmin, smt
/app/dealers/dealer-list

superAdmin
/app/users/user-list-photo

superAdmin, dealer
/app/users/friendsdealers

superAdmin, smt
/app/users/user-change-phone

superAdmin, smt
/app/users/second-hand-car

superAdmin, smt, dealer
/app/users/search-user

superAdmin, smt
/app/users/active-user


superAdmin, smt
/app/users/user-list

superAdmin, smt
/app/users/dealers

dealer
/app/users/dealers/users-by-default-dealer/

dealer
/app/users/dealers/users-by-dealer/

superAdmin, smt
/app/privilege/create-reward

superAdmin, smt
/app/privilege/reward-list

superAdmin, smt
/app/privilege/redeem-history

dealer
/app/privilege/redeem-history-by-dealer

dealer
/app/service-survey/dashboard-by-dealer

dealer
/app/service-survey/statistics-by-dealer

superAdmin, smt
/app/service-survey/dashboard

superAdmin, smt
/app/service-survey/statistics

superAdmin
/app/service-survey/statistics-by-date

dealer
/app/chat-history/dealers/users-by-dealer

superAdmin, smt, dealer
/app/service-survey/ranking

superAdmin, smt
/app/chat-history

superAdmin, smt
/app/contactUs

*/