import {
    GET_CAMPAIGN_RANK_ITEM_REQUEST,
    GET_CAMPAIGN_RANK_ITEM_SUCCESS,
    ON_SHOW_LOADER_GET_CAMPAIGN_RANK_ITEM,
    ON_HIDE_LOADER_GET_CAMPAIGN_RANK_ITEM,
    GET_CAMPAIGN_RANK_DATA_REQUEST,
    GET_CAMPAIGN_RANK_DATA_SUCCESS,
    ON_SHOW_LOADER_GET_CAMPAIGN_RANK_DATA,
    ON_HIDE_LOADER_GET_CAMPAIGN_RANK_DATA,
    RESET_CAMPAIGN_RANK_DATA,
    EXPORT_CAMPAIGN_RANK_TO_CSV_REQUEST,
    EXPORT_CAMPAIGN_RANK_TO_CSV_PROGRESS,
    EXPORT_CAMPAIGN_RANK_TO_CSV_SUCCESS,
    EXPORT_CAMPAIGN_RANK_TO_CSV_FAILURE
} from 'constants/ActionTypes';

const INIT_STATE = {
  loaderGetCampaignRankItems: false,
  loaderGetCampaignRankData: false,
  campaignRankItems: [],
  campaignRankData: [],
  exporting: false,
  progress: 0,
  error: null
};

//export const processingReducer = (state = INIT_STATE, action) => {
//export default function processingReducer(state = INIT_STATE, action) {
export default function campaignRankReport (state = INIT_STATE, action) {
//   console.log("Processing Reducer received action:", action);
//   console.log("Current state:", state);

  switch (action.type) {
    // case GET_CAMPAIGN_RANK_ITEM_REQUEST: {
    //   console.log("Handling rank items REQUEST");
    //   return {
    //       ...state,
    //       loaderGetCampaignRankItems: true,
    //       loaderGetCampaignRankData: false,
    //       //campaignRankItems: [],        
    //   };
    // }    
    case GET_CAMPAIGN_RANK_ITEM_SUCCESS: {
      console.log("Handling rank items SUCCESS");
      return {
          ...state,
          loaderGetCampaignRankItems: false,
          loaderGetCampaignRankData: false,
          campaignRankItems: action.payload,
      };
    };
    case ON_SHOW_LOADER_GET_CAMPAIGN_RANK_ITEM: {
        return {
          ...state,
          loaderGetCampaignRankItems: true
        };
    };
    case ON_HIDE_LOADER_GET_CAMPAIGN_RANK_ITEM: {
        return {
          ...state,
          loaderGetCampaignRankItems: false
        };
    };

    // case GET_CAMPAIGN_RANK_DATA_REQUEST: {
    //     console.log("Handling rank data REQUEST");
    //     return {
    //       ...state,
    //       loaderGetCampaignRankItems: false,
    //       loaderGetCampaignRankData: true,
    //       //campaignRankData: [],        
    //     };
    // };    
    case GET_CAMPAIGN_RANK_DATA_SUCCESS: {
      console.log("Handling rank data SUCCESS");
      return {
          ...state,
          loaderGetCampaignRankItems: false,
          loaderGetCampaignRankData: false,
          campaignRankData: action.payload,
      };
    };
    case ON_SHOW_LOADER_GET_CAMPAIGN_RANK_DATA: {
        return {
          ...state,
          loaderGetCampaignRankData: true
        };
    };
    case ON_HIDE_LOADER_GET_CAMPAIGN_RANK_DATA: {
        return {
          ...state,
          loaderGetCampaignRankData: false
        };
    };
    case RESET_CAMPAIGN_RANK_DATA: {
      return {
        ...state,
        campaignRankData: [],
      };
    };
    case EXPORT_CAMPAIGN_RANK_TO_CSV_REQUEST: {
      console.log("Handling EXPORT_CAMPAIGN_RANK_TO_CSV_REQUEST");
      return {
        ...state,
        exporting: true,
        progress: 0,
        error: null
      };
    };
      
    case EXPORT_CAMPAIGN_RANK_TO_CSV_PROGRESS: {
      console.log("Handling EXPORT_CAMPAIGN_RANK_TO_CSV_PROGRESS");
      return {
        ...state,
        progress: action.payload
      };
    };
      
    case EXPORT_CAMPAIGN_RANK_TO_CSV_SUCCESS: {
      console.log("Handling EXPORT_CAMPAIGN_RANK_TO_CSV_SUCCESS");
      return {
        ...state,
        exporting: false,
        progress: 1,
        error: null
      };
    };
      
    case EXPORT_CAMPAIGN_RANK_TO_CSV_FAILURE: {
      console.log("Handling EXPORT_CAMPAIGN_RANK_TO_CSV_FAILURE");
      return {
        ...state,
        exporting: false,
        error: action.payload.error
      };
    }
    default:
      return state;
  }
};