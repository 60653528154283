import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
//import { firebaseReducer } from 'react-redux-firebase';
//import { firestoreReducer } from 'redux-firestore';
import Settings from './Settings';
import Auth from './Auth';
import RegisterUserCount from './RegisterUserCount';
import Dealers from './Dealers';
import ServiceSurvey from './ServiceSurvey';
import Users from './Users';
import Chats from './Chats';
import Reward from './Reward';
import Redeem from './Redeem';
import ChangePhone from './ChangePhone';
import SecondHandCar from './SecondHandCar';
import Analytics from './Analytics';
import ContactUs from './ContactUs';
import Ranking from './Ranking';
import SearchUser from './SearchUser';
import ActiveUsers from './ActiveUsers';
import DeleteUserData from './DeleteUserData';
import CampaignRank from './CampaignRank';
import CampaignInactive from './CampaignInactive';
import CampaignRankReport from './CampaignRankReport';
import CampaignInactiveReport from './CampaignInactiveReport';
import ResetPage from './ResetPage';
import { RESET_PAGE_SUCCESS } from '../constants/ActionTypes';
// import campaignRankReport from './CampaignRankReport';
// import campaignInactiveReport from './CampaignInactiveReport';

//import pagesReducer from './ResetPage';
//import processingReducer from './processingReducer'; // Add this line

// export default history =>
//   combineReducers({
//     // firebase: firebaseReducer,
//     // firestore: firestoreReducer, // <- needed if using firestore
//     router: connectRouter(history),
//     settings: Settings,     
//     auth: Auth,
//     registerUserCount: RegisterUserCount,
//     dealers: Dealers,
//     serviceSurvey: ServiceSurvey,
//     users: Users,
//     chats: Chats,
//     reward: Reward,
//     redeem: Redeem,
//     changePhone: ChangePhone,
//     secondHandCar: SecondHandCar,
//     analytics: Analytics,
//     contactus: ContactUs,
//     ranking: Ranking,
//     searchUser: SearchUser,
//     activeUsers: ActiveUsers,
//     deleteUserData: DeleteUserData,     
//     campaignRank: CampaignRank,
//     resetPage: ResetPage,
//     //processing: processingReducer,  // Add this line 
//   });

export default history => {
  const appReducer = combineReducers({
    // firebase: firebaseReducer,
    // firestore: firestoreReducer, // <- needed if using firestore
    router: connectRouter(history),
    settings: Settings,     
    auth: Auth,
    registerUserCount: RegisterUserCount,
    dealers: Dealers,
    serviceSurvey: ServiceSurvey,
    users: Users,
    chats: Chats,
    reward: Reward,
    redeem: Redeem,
    changePhone: ChangePhone,
    secondHandCar: SecondHandCar,
    analytics: Analytics,
    contactus: ContactUs,
    ranking: Ranking,
    searchUser: SearchUser,
    activeUsers: ActiveUsers,
    deleteUserData: DeleteUserData,     
    campaignRank: CampaignRank,
    campaignInactive: CampaignInactive,
    campaignRankReport: CampaignRankReport,
    campaignInactiveReport: CampaignInactiveReport,
    resetPage: ResetPage,
    //processing: processingReducer,  // Add this line 
  });

  return (state, action) => {
    if (action.type === RESET_PAGE_SUCCESS) {
      // Preserve router state when resetting
      const { router } = state;
      state = { router };
    }
    return appReducer(state, action);
  };
};
