import {
    GET_CAMPAIGN_INACTIVE_ITEM_REQUEST,
    GET_CAMPAIGN_INACTIVE_ITEM_SUCCESS,
    ON_SHOW_LOADER_GET_CAMPAIGN_INACTIVE_ITEM,
    ON_HIDE_LOADER_GET_CAMPAIGN_INACTIVE_ITEM,
    GET_CAMPAIGN_INACTIVE_DATA_REQUEST,
    GET_CAMPAIGN_INACTIVE_DATA_SUCCESS,
    ON_SHOW_LOADER_GET_CAMPAIGN_INACTIVE_DATA,
    ON_HIDE_LOADER_GET_CAMPAIGN_INACTIVE_DATA,
    RESET_CAMPAIGN_INACTIVE_DATA,
    EXPORT_CAMPAIGN_INACTIVE_TO_CSV_REQUEST,
    EXPORT_CAMPAIGN_INACTIVE_TO_CSV_PROGRESS,
    EXPORT_CAMPAIGN_INACTIVE_TO_CSV_SUCCESS,
    EXPORT_CAMPAIGN_INACTIVE_TO_CSV_FAILURE
} from 'constants/ActionTypes';

const INIT_STATE = {
  loaderGetCampaignInactiveItems: false,
  loaderGetCampaignInactiveData: false,
  campaignInactiveItems: [],
  campaignInactiveData: [],
  exporting: false,
  progress: 0,
  error: null
};

//export const processingReducer = (state = INIT_STATE, action) => {
//export default function processingReducer(state = INIT_STATE, action) {
export default function campaignInactiveReport (state = INIT_STATE, action) {
//   console.log("Processing Reducer received action:", action);
//   console.log("Current state:", state);

  switch (action.type) {
    // case GET_CAMPAIGN_INACTIVE_ITEM_REQUEST: {
    //     console.log("Handling rank items REQUEST");
    //     return {
    //         ...state,
    //         loaderGetCampaignInactiveItems: true,
    //         loaderGetCampaignInactiveData: false,
    //         //campaignInactiveItems: [],        
    //     };
    // }    
    case GET_CAMPAIGN_INACTIVE_ITEM_SUCCESS: {
        console.log("Handling rank items SUCCESS");
        return {
            ...state,
            loaderGetCampaignInactiveItems: false,
            loaderGetCampaignInactiveData: false,
            campaignInactiveItems: action.payload,
        };
    };
    case ON_SHOW_LOADER_GET_CAMPAIGN_INACTIVE_ITEM: {
        return {
            ...state,
            loaderGetCampaignInactiveItems: true
        };
    };
    case ON_HIDE_LOADER_GET_CAMPAIGN_INACTIVE_ITEM: {
        return {
            ...state,
            loaderGetCampaignInactiveItems: false
        };
    };

    // case GET_CAMPAIGN_INACTIVE_DATA_REQUEST: {
    //     console.log("Handling rank data REQUEST");
    //     return {
    //         ...state,
    //         loaderGetCampaignInactiveItems: false,
    //         loaderGetCampaignInactiveData: true,
    //         //campaignInactiveData: [],        
    //     };
    // };    
    case GET_CAMPAIGN_INACTIVE_DATA_SUCCESS: {
        console.log("Handling rank data SUCCESS");
        return {
            ...state,
            loaderGetCampaignInactiveItems: false,
            loaderGetCampaignInactiveData: false,
            campaignInactiveData: action.payload,
        };
    };
    case ON_SHOW_LOADER_GET_CAMPAIGN_INACTIVE_DATA: {
        return {
            ...state,
            loaderGetCampaignInactiveData: true
        };
    };
    case ON_HIDE_LOADER_GET_CAMPAIGN_INACTIVE_DATA: {
        return {
            ...state,
            loaderGetCampaignInactiveData: false
        };
    };
    case RESET_CAMPAIGN_INACTIVE_DATA: {
        return {
            ...state,
            campaignInactiveData: [],
        };
    };
    case EXPORT_CAMPAIGN_INACTIVE_TO_CSV_REQUEST: {
        return {
            ...state,
            exporting: true,
            progress: 0,
            error: null
        };
    };
    
    case EXPORT_CAMPAIGN_INACTIVE_TO_CSV_PROGRESS: {
        return {
            ...state,
            progress: action.payload
        };
    };
    
    case EXPORT_CAMPAIGN_INACTIVE_TO_CSV_SUCCESS: {
        return {
            ...state,
            exporting: false,
            progress: 1,
            error: null
        };
    };
    
    case EXPORT_CAMPAIGN_INACTIVE_TO_CSV_FAILURE: {
        return {
            ...state,
            exporting: false,
            error: action.payload.error
        };
    };
    default:
      return state;
  }
};