import { all, takeLatest, takeEvery, call, fork, put } from 'redux-saga/effects';
import {    
    getCampaignRankItemSuccess,  
    hideLoaderCampaignRankItem,
    getCampaignRankDataSuccess,
    hideLoaderCampaignRankData,
    exportToCSVProgress,
    exportToCSVSuccess,
    exportToCSVFailure
} from 'actions/CampaignRankReport';
import {
    GET_CAMPAIGN_RANK_ITEM_REQUEST,    
    GET_CAMPAIGN_RANK_DATA_REQUEST,
    EXPORT_CAMPAIGN_RANK_TO_CSV_REQUEST
} from 'constants/ActionTypes';
import { getFirestore, collection, query, where, getDoc, getDocs } from 'firebase/firestore';
import { rsf, firebase, db } from '../firebase/firebase';
import { saveAs } from 'file-saver';
//import CSVWorker from '../workers/csvExport.worker.js';

// Web worker loader (configure your webpack to handle worker-loader)
//const CSVWorker = require('worker-loader!../workers/csvExport.worker.js');



function* getCampaignRankItems() {
  try {
    // const colRef = firebase.firestore().collection('DealersThailand');
    
    // const snapshot = yield call(rsf.firestore.getCollection, colRef);
   

    // Create reference to the RegisterUsers collection
    const colRef = collection(db, 'CampaignThailand');
    
    // Build query with date range
    // const q = query(
    //   colRef,
    //   where('date', '>=', startDate),
    //   where('date', '<=', endDate)
    // );

    // Build query with date range
    const q = query(
        colRef,
        where('campaignType', '==', 'CampaignRank'),
    );

    console.log('q Rank Items:' + q);
    
    // Fetch documents
    const snapshot = yield call(getDocs, q);

    //console.log('items:' + snapshot);
    let items = [];
    snapshot.forEach(doc => {
        //console.log(doc.data());
        var data = doc.data();
        data['id'] = data.campaignId;
        items.push(data);
        // datas = {
        //   id: doc.id,
        //   ...doc.data()
        // };
    });

    console.log('campaign Rank items count:' + items.length);
    
    yield put(getCampaignRankItemSuccess(items));
  } catch (error) {
    console.log(error);
    yield put(hideLoaderCampaignRankItem());
  }
}

function* getCampaignRankData({ payload }) {

  const { campaignId } = payload;

    console.log('campaignId..:' + campaignId);

    try {
       
        // Create reference to the RegisterUsers collection
        const colRef = collection(db, 'CarsThailand');
        
        // Build query with date range
        // const q = query(
        //   colRef,
        //   where('date', '>=', startDate),
        //   where('date', '<=', endDate)
        // );
    
        // Build query with date range
        const q = query(
            colRef,
            where('campaigns', '!=', null),
        );

        console.log('q Rank data:' + q);
        
        // Fetch documents
        const snapshot = yield call(getDocs, q);
    
        //console.log('items:' + snapshot);
        let items = [];
        snapshot.forEach(doc => {
            //console.log(doc.data());
            let data = doc.data();

            if (doc.data().campaigns != undefined) {
                for (const key in data) {
                    if (key === 'campaigns') {
                        let campaigns = data[key];

                        for (const keyItem in campaigns) {
                            if (keyItem === campaignId) {
                                let campaignItem = campaigns[keyItem];

                                //let dataItem;
                                campaignItem.firstname = data.firstname;
                                campaignItem.lastname = data.lastname;
                                campaignItem.licensePlate = data.licensePlate;
                                campaignItem.originRank = data.rank;
                                campaignItem.carModel = data.carModel;
                                campaignItem.deliveryDate = data.deliveryDate;
                                campaignItem.vinNumber = data.chassisNumber;
                                campaignItem.dealerCode = data.latestDealerCode;
                                campaignItem.carTimestamp = data.carTimestamp;                                

                                // dataItem.campaignId = campaignItem.campaignId;
                                // dataItem.campaignDate = campaignItem.campaignDate;
                                // dataItem.currentPoint = campaignItem.currentPoint;
                                // dataItem.getPointDate = campaignItem.getPointDate;
                                // dataItem.getPointTime = campaignItem.getPointTime;
                                // dataItem.isGetPoint = campaignItem.isGetPoint;
                                // dataItem.points = campaignItem.points;
                                // dataItem.summaryPoints = campaignItem.summaryPoints;
                                
                                items.push(campaignItem);
                            }
                        }
                    }
                }
            }


            //data['id'] = data.campaignId;
            //items.push(data);
            // datas = {
            //   id: doc.id,
            //   ...doc.data()
            // };

            
        });

        console.log('campaign Rank data count:' + items.length);
        
        yield put(getCampaignRankDataSuccess(items));
      } catch (error) {
        console.log(error);
        yield put(hideLoaderCampaignRankData());
      }
}

function* handleExportToCSV({ payload }) {
  const { data, fileName, headers } = payload;
  const CHUNK_SIZE = 5000; // Adjust based on performance

  console.log('handleExportToCSV dataCount:' + data.length + ' fileName:' + fileName + ' headers:' + headers);

  //const worker = new Worker(new URL('../workers/csvExport.worker.js', import.meta.url));

  let worker;
  
  //const CSVWorker = require('../workers/csvExport.worker.js');
  //const worker = new CSVWorker();

  
  
  try {

    // Create worker with absolute URL
    // const workerUrl = new URL('../../public/workers/csvExport.worker.js', import.meta.url);
    // const worker = new Worker(workerUrl, { type: 'classic' });

    // Auto-generate headers if not provided
    const finalHeaders = headers || (data.length > 0 ? Object.keys(data[0]) : []);

    // Webpack 5+ compatible worker instantiation
    // worker = new Worker(new URL('../workers/csvExport.worker.js', import.meta.url), {
    //   type: 'module'  // Only if using ES modules in worker
    // });
    
    //const worker = new CSVWorker();

    // Create worker using absolute path
    // const workerUrl = new URL('../workers/csvExport.worker.js', import.meta.url);
    // worker = new Worker(workerUrl);

    // worker = new Worker(
    //   process.env.NODE_ENV === 'production'
    //     ? new URL('../../dist/csvExport.worker.js', window.location.href)
    //     : new URL('../../src/workers/csvExport.worker.js', window.location.href)
    // );

    // For Webpack 4 or projects without import.meta support
    // worker = new Worker('../workers/csvExport.worker.js', {
    //   name: 'csvExportWorker'
    // });

    worker = new Worker('./csvExport.worker.js', { name: 'csvExportWorker' });

    let fullCSV = '';

    // worker.onmessage = (e) => {
    //   if (e.data.error) throw new Error(e.data.error);
    //   if (e.data.done) return;
    //   if (e.data.csv) fullCSV += e.data.csv + '\n';
    // };

    // Setup message handler first
    worker.onmessage = (event) => {
      console.log('Saga received worker message:', event.data);
      
      if (event.data.error) {
        console.log('data error :' + event.data.error);
        throw new Error(event.data.error);
      }
      
      if (event.data.done) {
        const blob = new Blob([fullCSV], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, fileName);
        return;
      }
      
      if (event.data.csv) {
        fullCSV += event.data.csv + '\n';
      }
      
      if (event.data.progress) {
        // Update progress if needed
        console.log('update progress:' + event.data.progress);
      }
    };

  
    worker.postMessage({
      command: 'startExport',
      data,
      headers: finalHeaders,
      chunkSize: CHUNK_SIZE
    });

    // Wait for completion
    yield call(() => new Promise((resolve) => {
      const completionHandler = (e) => {
        if (e.data.done) {
          worker.removeEventListener('message', completionHandler);
          resolve();
        }
      };
      worker.addEventListener('message', completionHandler);
    }));
    
    // worker.onmessage = (event) => {
    //   if (event.data.error) {
    //     console.log('data error :' + event.data.error);
    //     throw new Error(event.data.error);
    //   }
    //   if (event.data.done) {
    //     worker.terminate();
    //     const blob = new Blob([fullCSV], { type: 'text/csv;charset=utf-8;' });
    //     saveAs(blob, fileName);
    //     put(exportToCSVSuccess());
    //   } else {
    //     fullCSV += event.data.csv + '\n';
    //     console.log('progress:' + event.data.progress);
    //     put(exportToCSVProgress(event.data.progress));
    //   }
    // };
    
    // worker.onerror = (error) => {
    //   worker.terminate();
    //   console.log('export fail error:' + error.message);
    //   put(exportToCSVFailure(error.message));
    // };
    
  } catch (error) {
    console.error('Export error:', error);
    yield put(exportToCSVFailure(error.message));
  } finally {
    yield call([worker, 'terminate']);
    if (worker) {
      worker.terminate();
      console.log('Worker terminated');
    }
  }
}

function transformDataForExport(sourceData, mapping = null) {
  // If no mapping is provided, use a default mapping
  const fieldMapping = mapping || {
    // Define which source fields should map to which target fields
    // Format: targetField: sourceField
    // You can use dot notation for nested properties (e.g., 'user.id')
    id: 'id',
    name: 'name',
    category: 'category',
    amount: 'financial.amount',
    status: 'status',
    created: 'metadata.createdAt',
    updated: 'metadata.updatedAt',
    notes: 'description'
  };

  console.time('Data transformation');
  
  // Process in chunks to avoid blocking the main thread
  const chunkSize = 1000;
  const result = [];
  
  // Process chunks of data
  for (let i = 0; i < sourceData.length; i += chunkSize) {
    const chunk = sourceData.slice(i, i + chunkSize);
    
    // Transform each item in the chunk
    const transformedChunk = chunk.map(item => {
      const transformed = {};
      
      // Apply the mapping to create a new object with desired properties
      Object.entries(fieldMapping).forEach(([targetField, sourceField]) => {
        // Handle nested properties using dot notation
        if (sourceField.includes('.')) {
          const parts = sourceField.split('.');
          let value = item;
          
          // Traverse the object to get the nested value
          for (const part of parts) {
            value = value && value[part];
            if (value === undefined) break;
          }
          
          transformed[targetField] = value !== undefined ? value : null;
        } else {
          // Handle top-level properties
          transformed[targetField] = item[sourceField] !== undefined ? item[sourceField] : null;
        }
      });
      
      return transformed;
    });
    
    // Add the transformed chunk to the result
    result.push(...transformedChunk);
  }
  
  console.timeEnd('Data transformation');
  return result;
}

// Helper function to convert data to CSV format
function convertToCSV(data, headersCustom) {
  if (!data || !data.length) return '';
  
  // Extract column headers from first row
  const headers = Object.keys(data[0]);
  
  // Create CSV header row
  const csvHeader = headers.join(',');
  
  // Process data in chunks to handle large datasets efficiently
  const chunkSize = 1000;
  let csvBody = '';

  const csvHeaderCustom = headersCustom.join(',');
  
  for (let i = 0; i < data.length; i += chunkSize) {
    const chunk = data.slice(i, i + chunkSize);
    
    // Convert each row to CSV format
    const chunkRows = chunk.map(row => {
      return headers.map(header => {
        // Handle special cases (commas, quotes, null values)
        const cell = row[header] === null || row[header] === undefined ? '' : row[header];
        const cellStr = String(cell);
        
        // Escape quotes and wrap in quotes if contains commas, quotes or newlines
        if (cellStr.includes(',') || cellStr.includes('"') || cellStr.includes('\n')) {
          return `"${cellStr.replace(/"/g, '""')}"`;
        }
        return cellStr;
      }).join(',');
    }).join('\n');
    
    csvBody += chunkRows + (i + chunkSize < data.length ? '\n' : '');
  }
  
  //return `${csvHeader}\n${csvBody}`;
  return `${csvHeaderCustom}\n${csvBody}`;
}

// Worker saga for CSV export
function* exportCsvWorker({ payload }) {
  try {
    //const { data, filename } = action.payload;

    const { data, fileName, headers } = payload;

    console.log('data count:' + data.length);
    console.log('filename:' + fileName);
    console.log('headers:' + headers);
    
    // Convert data to CSV
    const csv = yield call(convertToCSV, data, headers);
    
    // Create blob and trigger download
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
    yield call(saveAs, blob, fileName);
    
    yield put(exportToCSVSuccess());
  } catch (error) {
    yield put(exportToCSVFailure(error.message));
  }
}



export function* watchCampaignRankItemsRequest() {
  yield takeLatest(GET_CAMPAIGN_RANK_ITEM_REQUEST, getCampaignRankItems);
}
export function* watchCampaignRankDataRequest() {
  yield takeLatest(GET_CAMPAIGN_RANK_DATA_REQUEST, getCampaignRankData);
}
// export function* watchCampaignRankHandleExportToCSV() {
//   yield takeLatest(EXPORT_CAMPAIGN_RANK_TO_CSV_REQUEST, handleExportToCSV);
// }
export function* watchCampaignRankHandleExportToCSV() {
  yield takeLatest(EXPORT_CAMPAIGN_RANK_TO_CSV_REQUEST, exportCsvWorker);
}


export default function* rootSaga() {
  yield all([
    fork(watchCampaignRankItemsRequest),
    fork(watchCampaignRankDataRequest),
    fork(watchCampaignRankHandleExportToCSV),
  ]);
}