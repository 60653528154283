import {
  CHANGE_DIRECTION,
  CHANGE_NAVIGATION_STYLE,
  FIXED_DRAWER,
  HORIZONTAL_MENU_POSITION,
  INSIDE_THE_HEADER,
  SWITCH_LANGUAGE,
  TOGGLE_COLLAPSED_NAV,
  VERTICAL_NAVIGATION,
  WINDOW_WIDTH,
  UPDATE_FORM_DATA,
} from 'constants/ActionTypes';
import secondHandCar from './SecondHandCar';
import moment from 'moment-timezone';

const rltLocale = ['ar']; //ar if change this to en the side bar menu when go to the left side when change to English language
// const initLanguage = localStorage.getItem('language')
//   ? JSON.stringify(localStorage.getItem('language'))
//   : {
//       languageId: 'english',
//       locale: 'en',
//       name: 'English',
//       icon: 'gb'
//     };
const initialSettings = {
  navCollapsed: false,
  drawerType: FIXED_DRAWER,
  width: window.innerWidth,
  isDirectionRTL: false,
  navigationStyle: VERTICAL_NAVIGATION,
  horizontalNavPosition: INSIDE_THE_HEADER,
  // locale: {
  //   languageId: 'english',
  //   locale: 'en',
  //   name: 'English',
  //   icon: 'gb'
  // },
  // locale: {
  //   languageId: 'thai',
  //   locale: 'th',
  //   name: 'Thai',
  //   icon: 'th'
  // },
  //locale: initLanguage,
  locale: localStorage.getItem('language')
  ? JSON.parse(localStorage.getItem('language'))
  : {
      languageId: 'english',
      locale: 'en',
      name: 'English',
      icon: 'gb'
    },
  langFormData: {
    changePhone: {
      oldPhone: '',
      newPhone: '',
      vinNumber: '',
    },
    secondHandCar: {
      phoneNumber: '',
      vinNumber: '',
      isActive: false,
    },
    campaignRank: {
      rank: '',
      campaignNameEn: '',
      campaignNameTh: '',
      // campaignNameEn: 'You can redeem points at any Suzuki dealership nationwide',
      // campaignNameTh: 'คุณสามารถแลกของรางวัลได้ที่ศูนย์ซูซูกิทั่วประเทศ',
      points: '',
      dealerCode: '000000'
    },
    campaignInactive: {
      inactiveDate: new Date('2020-01-17'),
      // new Date(
      //   moment()
      //     .tz('Asia/Bangkok')
      //     .startOf('month')
      //     .format('YYYY-MM-DD')
      // ),
      diffDateStr: '',
      campaignNameEn: '',
      campaignNameTh: '',
      // campaignNameEn: 'You can redeem points at any Suzuki dealership nationwide',
      // campaignNameTh: 'คุณสามารถแลกของรางวัลได้ที่ศูนย์ซูซูกิทั่วประเทศ',
      points: '',
      dealerCode: '000000'
    },
    campaignRankReport: {
      campaignId: '',
      campaignRankItemObject: null
    },
    campaignInactiveReport: {
      campaignId: '',
      campaignInactiveItemObject: null
    }
  }
};

const settings = (state = initialSettings, action) => {

  // console.log("Processing Reducer received action:", action);
  // console.log("Current state:", state);

  switch (action.type) {
    case '@@router/LOCATION_CHANGE':
      return {
        ...state,
        navCollapsed: false
      };
    case TOGGLE_COLLAPSED_NAV:
      return {
        ...state,
        navCollapsed: action.isNavCollapsed
      };
    case WINDOW_WIDTH:
      return {
        ...state,
        width: action.width
      };
    case SWITCH_LANGUAGE:
      localStorage.setItem('language', JSON.stringify(action.payload));
      //console.log('language:' + JSON.stringify(action.payload))
      return {
        ...state, // Spread existing state
        locale: action.payload, // Update locale
        isDirectionRTL: rltLocale.includes(action.payload.locale), // Update RTL status
        langFormData: state.langFormData,
      };
    case CHANGE_DIRECTION:
      return {
        ...state,
        isDirectionRTL: !state.isDirectionRTL
      };

    case CHANGE_NAVIGATION_STYLE:
      return {
        ...state,
        navigationStyle: action.payload
      };

    case HORIZONTAL_MENU_POSITION:
      return {
        ...state,
        horizontalNavPosition: action.payload
      };

    case UPDATE_FORM_DATA:
      return {
        ...state,
        langFormData: {
          ...state.langFormData,
          [action.payload.formName]: {          
            ...state.langFormData[action.payload.formName],
            [action.payload.field]: action.payload.value
          }
        }
      };

    default:
      return state;
  }
};

export default settings;
